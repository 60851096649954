import {
  DataGridPremium,
  gridFilteredTopLevelRowCountSelector,
  GridFilterPanel, GridFooter,
  GridOverlay,
  gridPageSizeSelector,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import MuiPagination from '@mui/material/Pagination';
import {Box, LinearProgress, Stack, styled, useTheme} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import Empty from '../Empty/Empty';
import theme from '../../../vendor/mui/theme';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {ArrowDownward, ArrowUpward, FilterList} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import useBreakPoint from '../../../hooks/ui/useBreakpoint';

function DataGridReusable({
                            rows,
                            columns,
                            getRowId,
                            handleRowClick = () => {
                            },
                            showRowCursorPointer,
                            isLoading = false,
                            uniqueSlug = '',
                            emptyHeight = '400px',
                            disableToolbar = false,
                            disableExport = true,
                            exportFileName = 'Pallapp',
                            exportButtonSlot,
                            disableFilters = false,
                            defaultPageSize = 10,
                            disablePaginationHistory = false,
                            isPackageDetail,
                            rowCount,
                            showPagination = true,
                            paginationMode = 'client' || 'server',
                            filterMode = 'client' || 'server',
                            sortMode = 'client' || 'server',
                            columnVisibilityModel = null,
                            rowReordering = false,
                            slots = {},
                            onPaginationModelChange = () => {
                            },
                            onFilterModelChange = () => {
                            },
                            onSortModelChange = () => {
                            },
                            onRowOrderChange = () => {
                            },
                            onFilterClick = () => {
                            },
                          }) {

  const [searchParams, setSearchParams] = useSearchParams();

  const apiRef = useGridApiRef();
  const gridToolbarPanelRef = useRef(null);

  const cachedPageSize = searchParams.get(
      `${uniqueSlug ? uniqueSlug + '-' : ''}pageSize`) || defaultPageSize;

  const cachedPage = searchParams.get(
      `${uniqueSlug ? uniqueSlug + '-' : ''}page`) || 1;

  const pageSizeOptions = [10, 20, 30, 50, 100];

  const paginationModelDefault = {
    pageSize: +cachedPageSize,
    page: +cachedPage - 1,
  };

  const [paginationModel, setPaginationModel] = useState(
      paginationModelDefault);

  const [filterModel, setFilterModel] = useState();

  const [sortModel, setSortModel] = useState();

  function handlePaginationModelChange(model) {
    if (!disablePaginationHistory && paginationMode === 'server') {
      const existingParams = {};
      for (let entry of searchParams.entries()) {
        existingParams[entry[0]] = entry[1];
      }
      setSearchParams({
        ...existingParams,
        [`${uniqueSlug ? uniqueSlug + '-' : ''}page`]: model.page + 1,
        [`${uniqueSlug ? uniqueSlug + '-' : ''}pageSize`]: model.pageSize,
      });
    }
    onPaginationModelChange(model);
    setPaginationModel(model);
  }

  function handleFilterModelChange(model) {
    setFilterModel(model);
    onFilterModelChange(model);
  }

  function handleSortModelChange(model) {
    onSortModelChange(model);
  }

  function handleOnRowClick(params) {
    const allHidden = params.columns.every(
        (col) => col.computedWidth === 0);
    if (!allHidden) {
      handleRowClick(params);
    }
  }

  return (
      <Box
          sx={{
            height:
                (!rows && isLoading) ||
                (rows && rows?.length === 0 && !isLoading)
                    ? emptyHeight
                    : '',
          }}
      >
        <DataGridPremium
            apiRef={apiRef}
            pagination={true}
            autoHeight={!isLoading && rows && rows.length > 0}
            loading={isLoading}
            getRowId={getRowId}
            disableDensitySelector
            disableRowSelectionOnClick={true}
            hideFooterSelectedRowCount={true}
            disableColumnMenu={true} //disbale the column menu feature in premium
            paginationMode={paginationMode}
            filterMode={filterMode}
            filterModel={filterModel}
            sortingMode={sortMode}
            rowReordering={rowReordering}
            onRowOrderChange={onRowOrderChange}
            columns={columns}
            rows={rows || []}
            rowCount={rowCount}
            pageSizeOptions={pageSizeOptions}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            onRowClick={handleOnRowClick}
            {...(columnVisibilityModel && {columnVisibilityModel})}
            slots={{
              toolbar: disableToolbar ? null : CustomGridToolbar,
              // footer: CustomFooter,
              loadingOverlay: CustomGridLoadingOverlay,
              pagination: showPagination && CustomGridPagination,
              noRowsOverlay: CustomGridNoRowsOverlay,
              filterPanel: CustomGridFilterPanel,
              columnSortedAscendingIcon: StyledAscendingIcon,
              columnSortedDescendingIcon: StyledDescendingIcon,
              columnUnsortedIcon: ({sortingOrder, ...rest}) => {
                return <StyledUnsortedIcon {...rest} />;
              },
              columnFilteredIcon: StyledFilteredIcon,
              ...slots,
            }}
            slotProps={{
              toolbar: {
                disableFilters,
                disableExport,
                exportFileName,
                exportButtonSlot,
                onFilterClick,
                gridToolbarPanelRef,
              },
              baseSelect: {
                native: false,
              },
              panel: {
                anchorEl: () => {
                  return gridToolbarPanelRef.current;
                },
              },

            }}
            sx={{
              ...(!isLoading && !rows) && {
                height: '400px',
              },

              ...(!isLoading && rows && rows.length === 0) && {
                height: '400px',
              },
              '.MuiDataGrid-columnHeaderTitle': {
                // color: isPackageDetail ? 'text.secondary' : 'initial',
              },
              '& .MuiDataGrid-virtualScroller': {
                // overflow: 'hidden',
              },
              '.MuiDataGrid-row': {
                cursor: showRowCursorPointer ? 'pointer' : '',
              },
            }}
        />
      </Box>
  );
}

export function CustomGridPagination(props) {
  return <GridPagination ActionsComponent={CustomPaginationActions} {...props}
                         sx={{
                           [theme.breakpoints.down('sm')]: {
                             '.MuiToolbar-root': {
                               display: 'flex',
                               alignItems: 'center',
                               flexWrap: 'wrap',
                               justifyContent: 'flex-start',
                               flexDirection: 'row-reverse',
                               rowGap: 1,
                               columnGap: 1,
                             },
                             '.MuiTablePagination-selectLabel': {
                               display: 'none',
                             },
                             '.MuiTablePagination-input': {
                               display: 'inline-flex',
                               order: 2,
                               margin: 0,
                             },
                             '.MuiTablePagination-displayedRows': {
                               order: 1,
                               margin: 0,
                             },
                             '.MuiPagination-root': {
                               order: 0,
                               margin: 0,
                               '.MuiPagination-ul': {},
                             },
                           },

                         }}/>;
}



function CustomPaginationActions(props) {

  const getPageCount = (rowCount, pageSize) => {
    if (pageSize > 0 && rowCount > 0) {
      return Math.ceil(rowCount / pageSize);
    }

    return 0;
  };

  const {page, onPageChange, className} = props;
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const visibleTopLevelRowCount = useGridSelector(
      apiRef,
      gridFilteredTopLevelRowCountSelector,
  );
  const rootProps = useGridRootProps();
  const pageCount = getPageCount(
      rootProps.rowCount ?? visibleTopLevelRowCount,
      pageSize,
  );
  const {isLgUp, isLgDown, isSmDown} = useBreakPoint();

  let size;

  if (isLgUp) size = 'medium';
  if (isLgDown) size = 'medium';
  if (isSmDown) size = 'small';

  return (
      <MuiPagination
          size={size}
          // color="inherit"
          className={className}
          count={pageCount}
          page={page + 1}
          showFirstButton
          showLastButton
          onChange={(event, newPage) => {
            onPageChange(event, newPage - 1);
          }}
      />
  );
}

function CustomGridFilterPanel() {
  return <GridFilterPanel
      sx={{
        '& .MuiDataGrid-filterForm': {p: 2, width: '100%', display: 'flex'},
        '& .MuiDataGrid-filterFormLogicOperatorInput': {mr: 2},
        '& .MuiDataGrid-filterFormColumnInput': {mr: 2, width: 150},
        '& .MuiDataGrid-filterFormOperatorInput': {mr: 2, minWidth: 150},
        '& .MuiDataGrid-filterFormValueInput': {},
      }}
      filterFormProps={{
        logicOperatorInputProps: {
          variant: 'outlined',
          size: 'small',
        },
        columnInputProps: {
          variant: 'outlined',
          size: 'small',
          sx: {mt: 'auto'},
        },
        operatorInputProps: {
          variant: 'outlined',
          size: 'small',
          sx: {mt: 'auto'},
        },
        valueInputProps: {
          InputComponentProps: {
            variant: 'outlined',
            size: 'small',
          },
        },
        deleteIconProps: {
          sx: {
            '& .MuiSvgIcon-root': {color: theme.palette.error.main},
          },
        },
      }}
  />;
}

function CustomGridLoadingOverlay() {
  return (
      <GridOverlay style={{position: 'relative'}}>
        <div style={{position: 'absolute', top: 0, width: '100%'}}>
          <LinearProgress color="primary"/>
        </div>
      </GridOverlay>
  );
}

function CustomGridNoRowsOverlay() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  if (isVisible) {
    return <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
    >
      <Empty imageStyle={'simple'}/>
    </Box>;
  }

}

function CustomGridToolbar({
                             disableExport,
                             disableFilters,
                             exportFileName,
                             exportButtonSlot: ExportButtonSlot,
                             onFilterClick,
                             gridToolbarPanelRef,
                           }) {
  const theme = useTheme();
  const {t} = useTranslate();

  return (
      <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'space-between'}
          width={'100%'}
          py={'20px'}
          px={'20px'}
          alignItems={'center'}

      >

        <Stack direction={'row'} alignItems={'center'}>
          <GridToolbarQuickFilter
              // style={{marginLeft: 'auto'}}
              size={'medium'}
              variant={'outlined'}
              sx={{
                'svg': {
                  fill: theme.palette.grey[500],
                },
              }}
          />
        </Stack>

        <Stack
            direction={'row'} align={'center'}
        >
          <Box mr={1}>
            <GridToolbarColumnsButton
                variant={'text'}
            />
          </Box>
          {
              !disableFilters &&
              <Box mr={1}>
                <GridToolbarFilterButton
                    variant={'text'}
                />
              </Box>
          }
          {/*Hack due to mui toolbar panel positioning*/}
          <Box ref={gridToolbarPanelRef}> </Box>
          {
              !disableExport && !ExportButtonSlot &&
              <GridToolbarExport
                  csvOptions={{
                    fileName: exportFileName,
                    utf8WithBom: true,
                    allColumns: true,
                  }}
                  excelOptions={{
                    fileName: exportFileName,
                  }}
                  variant={'text'}
              />
          }

          {
              !disableExport && !!ExportButtonSlot &&
              ExportButtonSlot
          }
        </Stack>


      </Stack>
  );
}

const StyledAscendingIcon = styled(ArrowUpward)(({theme}) => {
  return {
    color: theme.palette.primary.main,
  };
});

const StyledDescendingIcon = styled(ArrowDownward)(({theme}) => {
  return {
    color: theme.palette.primary.main,
  };
});

const StyledFilteredIcon = styled(FilterList)(({theme}) => {
  return {
    color: theme.palette.primary.main,
  };
});

const StyledUnsortedIcon = styled(ArrowUpwardIcon)(({theme}) => {
  return {
    color: theme.palette.grey[600],
  };
});

export default DataGridReusable;

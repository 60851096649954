function PlumpyClubs(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M21.947,7.974c0.057-1.01-0.652-1.937-1.651-2.102C16.145,5.189,16.464,2,12,2C7.536,2,7.855,5.189,3.704,5.872C2.705,6.036,1.996,6.963,2.053,7.974C2.481,15.604,5.534,19.229,12,22C18.466,19.229,21.519,15.604,21.947,7.974z"
              opacity=".35" fill="currentColor"/>
      </svg>
  );
}

export default PlumpyClubs;

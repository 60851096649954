
export default function TableOverride(theme) {
  return {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: theme.palette.grey[100],
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableRow-root': {
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          },
        },
      },
    },
  };
}

import {Route, Routes} from 'react-router-dom';
import React, {lazy} from 'react';

import AccessAuthenticated from './access/AccessAuthenticated';
import AccessMaster from './access/AccessMaster';
import InitClubManagers from './init/InitClubManagers';
import InitSuperAdmin from './init/InitSuperAdmin';
import LayoutMain from './layouts/LayoutMain/LayoutMain';
import LayoutTemparoryMain from './layouts/LayoutTemparory/LayoutTemparoryMain';
import RoutesRestricted from './routes/ClubManager/RoutesRestricted';
import RoutesSchedule from './routes/ClubManager/RoutesSchedule';
import RoutesBookings from './routes/ClubManager/RoutesBookings';
import RoutesCommunications from './routes/ClubManager/RoutesCommunications';
import RoutesFinancials from './routes/ClubManager/RoutesFinancials';
import RoutesWallets from './routes/ClubManager/RoutesWallets';
import RoutesEvents from './routes/ClubManager/RoutesEvents';
import RoutesCustomers from './routes/ClubManager/RoutesCustomers';
import RoutesReports from './routes/ClubManager/RoutesReports';
import RoutesSettings from './routes/ClubManager/RoutesSettings';
import RoutesSettingsCourts from './routes/ClubManager/RoutesSettingsCourts';
import RoutesSettingsClubs from './routes/ClubManager/RoutesSettingsClubs';
import RoutesSettingsExtras from './routes/ClubManager/RoutesSettingsExtras';
import RoutesSettingsHolidays
  from './routes/ClubManager/RoutesSettingsHolidays';
import RoutesSettingsManageAdmins
  from './routes/ClubManager/RoutesSettingsManageAdmins';
import RoutesSettingsPricing from './routes/ClubManager/RoutesSettingsPricing';
import RoutesSettingsCompany from './routes/ClubManager/RoutesSettingsCompany';
import RoutesPolicies from './routes/ClubManager/RoutesPolicies';
import RoutesProfile from './routes/ClubManager/RoutesProfile';
import RoutesPackages from './routes/ClubManager/RoutesPackages';
import useIsRole from './hooks/access/useIsRole';
import RoutesSuperAdmin from './routes/SuperAdmin/RoutesSuperAdmin';
import RoutesSettingsIntegration
  from './routes/ClubManager/RoutesSettingsIntegration';
import RoutesMeshlink from './routes/ClubManager/RoutesMeshlink';
import VersionUpdate from './components/_Global/VersionUdate/VersionUpdate';
import PageFinanceFullData from './pages/Finances/PageFinanceFullData';
import RoutesOpenMatches from './routes/ClubManager/RoutesOpenMatches';
import { useSelectedClub } from './hooks/clubs/useSelectedClub';
import RoutesTraining from './routes/ClubManager/RoutesTraining';
import { isTestClub } from './utils/ui';
const PageLoginForgotPassword = lazy(() => import('./pages/Login/PageLoginForgotPassword'));
const PageLogin = lazy(() => import('./pages/Login/PageLogin'));
const PageInitDemo = lazy(() => import('./pages/Demo/PageInitDemo'));
const PageLoginResetPassword = lazy(() => import('./pages/Login/PageLoginResetPassword'));
const  PageBookingFullData = lazy(()=> import( './pages/Bookings/PageBookingFullData'));
const  PageRecurringBookingFullData = lazy(()=> import( './pages/Bookings/PageRecurringFullData'));
const  PageCustomersFullData = lazy(()=> import( './pages/Customers/PageCustomersFullData'));
const  PageFinanceTransactionFullData = lazy(()=> import( './pages/Finances/PageFinanceTransactionFullData'));

function App() {

  const isSuperAdmin = useIsRole('super_admin');
  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;
  const isOwnClub = isTestClub(clubId);

  return (
      <>
        <Routes>
          <Route element={<AccessMaster/>}>

            {/*Public Routes*/}
            <Route path="/login"
                   element={<PageLogin/>}
            />
            <Route path="/demo"
                   element={<PageInitDemo/>}
            />
            <Route path="/forgot-password"
                   element={<PageLoginForgotPassword/>}
            />
            <Route path="/reset-password/:token"
                   element={<PageLoginResetPassword/>}
            />

            {/*Authenticated Routes*/}
            <Route element={<AccessAuthenticated/>}>
              {
                  isSuperAdmin &&
                  <Route element={<InitSuperAdmin/>}>
                    <Route element={<LayoutMain role={'super_admin'}/>}>
                      {RoutesSuperAdmin}
                    </Route>
                  </Route>
              }
              {
                  !isSuperAdmin &&
                  <Route element={<InitClubManagers/>}>
                    <Route
                        element={<LayoutTemparoryMain role={'club_manager'}/>}>
                      <Route element={<PageBookingFullData/>}
                             path="/bookings/:name"/>
                      <Route element={<PageRecurringBookingFullData/>}
                             path="/recurring/bookings"/>
                      <Route element={<PageCustomersFullData/>}
                             path="/customers/list"/>
                      <Route element={<PageFinanceFullData/>}
                             path="/finances/list"/>

                  </Route>
                  <Route element={<LayoutMain role={'club_manager'}/>}>
                    {RoutesRestricted}
                    {RoutesProfile}
                    {RoutesSchedule}
                    {RoutesBookings}
                    {RoutesCommunications}
                    {RoutesFinancials}
                    {RoutesWallets}
                    {RoutesEvents}
                    {RoutesCustomers}
                    {RoutesReports}
                    {RoutesSettings}
                    {RoutesSettingsCourts}
                    {RoutesSettingsClubs}
                    {RoutesSettingsExtras}
                    {RoutesSettingsHolidays}
                    {RoutesSettingsManageAdmins}
                    {RoutesSettingsPricing}
                    {RoutesSettingsCompany}
                    {RoutesPackages}
                    {RoutesPolicies}
                    {RoutesSettingsIntegration}
                    {RoutesMeshlink}
                    {isOwnClub && RoutesOpenMatches}
                    {RoutesTraining}
                  </Route>
                </Route>
            }

            </Route>

          </Route>
        </Routes>
        <VersionUpdate/>
      </>
  );

}

export default App;


import {shadows} from '../shadows';

export default function DataGridOverride(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {

          borderStyle: 'none',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: shadows('light')[8],

          '.MuiDataGrid-columnHeaders': {

            backgroundColor: theme.palette.grey[100],

            '.MuiDataGrid-columnHeaderTitleContainer': {
              color: theme.palette.grey[800],
            },

            '.MuiDataGrid-row--borderBottom': {
              background: 'none',
            },

          },

          'div[class*=--pinnedLeft]': {
            borderRight: 'none',
            backgroundColor: 'inherit',

            [theme.breakpoints.down('md')]: {
              backgroundColor: '#fff',
            },
          },

          '.MuiDataGrid-row': {
            '.MuiDataGrid-cell': {
              color: theme.palette.text.primary,
            },
          },
          '.MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within,.MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },

          '.MuiDataGrid-footerContainer': {
            minHeight: '64px',
            '.MuiTablePagination-root': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          },

          '.MuiTablePagination-root': {
            '.MuiTablePagination-spacer': {
              display: 'none',
            },
            '.MuiToolbar-root': {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '24px !important',
            },
            '.MuiTablePagination-displayedRows': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            ".MuiTablePagination-input":{
              "svg":{
                right:'3px',
                top:'calc(50% - 10px)',
              }
            }
          },

        },
      },
    },
  };
}

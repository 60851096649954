function PlumpyOpen(props){
 return (
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} {...props}>
       <rect width="22" height="11" x="1" y="9" opacity=".35" rx="3"
             fill="currentColor"/>
       <path
           d="M6.805,9,11.46,5.2a.859.859,0,0,1,1.079,0L17.2,9h3.162L13.8,3.648a2.863,2.863,0,0,0-3.609,0L3.643,9Z"
           fill="currentColor"/>
       <path
           d="M13.21,16.555v-4.11a.736.736,0,0,1,.736-.736h1.4a.556.556,0,0,1,.556.556h0a.556.556,0,0,1-.556.556h-.934v1.1h.851a.521.521,0,0,1,.521.521h0a.52.52,0,0,1-.521.52h-.851v1.2h1.029a.556.556,0,0,1,.556.556v.024a.556.556,0,0,1-.556.556h-1.5A.736.736,0,0,1,13.21,16.555Z"
           fill="currentColor"/>
       <path
           d="M20.2,17.291a.8.8,0,0,1-.746-.514l-1.1-2.884h-.023l.027,2.851a.543.543,0,0,1-.543.547h-.038a.542.542,0,0,1-.542-.542V12.507a.8.8,0,0,1,.8-.8h.036a.8.8,0,0,1,.748.519l1.091,2.926h.023l-.019-2.9a.542.542,0,0,1,.542-.546h0a.542.542,0,0,1,.542.542v4.242a.8.8,0,0,1-.8.8Z"
           fill="currentColor"/>
       <path
           d="M7.289,14.484c0,.441-.125,2.957-2.145,2.957S3,14.925,3,14.484s.106-2.925,2.144-2.925S7.289,14.042,7.289,14.484ZM6,14.484c0-.242-.027-1.7-.852-1.7s-.851,1.461-.851,1.7.046,1.719.851,1.719S6,14.726,6,14.484Z"
           fill="currentColor"/>
       <path
           d="M11.972,13.459a1.668,1.668,0,0,1-1.664,1.8H9.765v1.415a.619.619,0,0,1-.619.619h0a.62.62,0,0,1-.62-.619V12.445a.737.737,0,0,1,.737-.736h1A1.615,1.615,0,0,1,11.972,13.459Zm-1.183.024c0-.631-.449-.71-.622-.71h-.4v1.459h.4A.656.656,0,0,0,10.789,13.483Z"
           fill="currentColor"/>
     </svg>
 )
}

export default PlumpyOpen;

import {Chip} from '@mui/material';
import {useTranslate} from '@tolgee/react';

function ChipFinancialStatus({status, label}) {

  const {t} = useTranslate();

  let color;

  switch (status) {
    case 'open':
      color = 'success';
      break;
    case 'paid':
      color = 'success';
      break;
    case 'expired':
      color = 'error';
      break;
    case 'unpaid':
      color = 'error';
      break;
    case 'failed':
      color = 'error';
      break;
  }

  return (
      <Chip
          size={'small'}
          label={label ?? t(`global.types.transactions.status.${status}`)}
          color={color}

      />
  );
}

export default ChipFinancialStatus;

function PlumpyCustomers(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <circle cx="7.5" cy="6.5" r="3.5" opacity=".35" fill="currentColor"/>
          <circle cx="16.5" cy="6.5" r="3.5" opacity=".35" fill="currentColor"/>
          <path d="M10.5,14h8c1.93,0,3.5,1.57,3.5,3.5S20.43,21,18.5,21h-8V14z"
                opacity=".35" fill="currentColor"/>
          <path
              d="M14,17.5c0,1.93-1.57,3.5-3.5,3.5h-5c-0.96,0-1.84-0.39-2.47-1.03C2.39,19.34,2,18.46,2,17.5C2,15.57,3.57,14,5.5,14h5c0.96,0,1.84,0.39,2.47,1.03C13.61,15.66,14,16.54,14,17.5z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyCustomers;

import {useEffect, useState} from 'react';
import {clubsSwitcher_GET} from '../vendor/redux/actions/clubs-switcher';
import {locationsCountriesList_GET} from '../vendor/redux/actions/locations';
import {Outlet} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import useSwitcherClubs from '../hooks/clubs/useSwitcherClubs';
import useUser from '../hooks/access/useUser';
import {useSelectedClub} from '../hooks/clubs/useSelectedClub';
import {clubsPolicyList_GET} from '../vendor/redux/actions/policies';
import {setSelectedClub} from '../vendor/redux/actions/selected-club';
import axios from 'axios';
import {Box, LinearProgress} from '@mui/material';
import {companyLocalize_GET} from '../vendor/redux/actions/company';
import {clubsIntegrationList_GET} from '../vendor/redux/actions/clubs';
import useHasCapability from '../hooks/access/useHasCapability';
import {CAPABILITY_READ} from '../constants/capabilities';
import {MODULE_INTEGRATIONS} from '../constants/modules';

function InitClubManagers() {

  const dispatch = useDispatch();

  const user = useUser();

  const switcherClubs = useSwitcherClubs();
  const selectedClub = useSelectedClub();

  const companyId = user?.userObj?.companyId;
  const clubId = selectedClub?.id;

  const [hasSetSwitcherClub, setHasSetSwitcherClub] = useState(false);
  const [hasSetCompanyDefaults, setHasSetCompanyDefaults] = useState(false);
  const [hasFetchedCountriesList, setHasFetchedCountriesList] = useState(false);
  const [hasFetchedPolicies, setHasFetchedPolicies] = useState(false);
  const [hastFetchClubsIntegrations, setHasFetchClubsIntegrations] = useState(
      false);
  const [hasSetHeaders, setHasSetHeaders] = useState(false);

  const CAN_READ_INTEGRATIONS = useHasCapability(CAPABILITY_READ,
      MODULE_INTEGRATIONS);

  const isLoading = !hasSetSwitcherClub || !hasSetCompanyDefaults ||
      !hasFetchedCountriesList ||
      !hasSetHeaders;

  function fetchSwitcherClubs(companyId) {
    dispatch(clubsSwitcher_GET(companyId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubSwitcher_GET Success', res);
    }

    function cbFail(e) {
      console.log('clubsSwitcher_GET Fail', e);
    }
  }

  function fetchCompanyDefaults(companyId) {

    dispatch(companyLocalize_GET(companyId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('companyLocalize_GET Success', res);
      setHasSetCompanyDefaults(true);
    }

    function cbFail(e) {
      console.log('companyLocalize_GET Fail', e);
    }
  }

  function fetchClubPolicies(clubId) {

    dispatch(clubsPolicyList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubsPolicyList_GET Success', res);
      setHasFetchedPolicies(true);
    }

    function cbFail(e) {
      console.log('clubsPolicyList_GET Fail', e);
    }

  }

  function fetchClubIntegrations(clubId) {

    dispatch(clubsIntegrationList_GET(clubId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('clubsPolicyList_GET Success', res);
      setHasFetchClubsIntegrations(true);
    }

    function cbFail(e) {
      console.log('clubsPolicyList_GET Fail', e);
    }

  }

  function fetchCountriesList() {
    dispatch(locationsCountriesList_GET(cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('locationsCountriesList_GET Success', res);
      setHasFetchedCountriesList(true);
    }

    function cbFail(e) {
      console.log('locationsCountriesList_GET Fail', e);
      setHasFetchedCountriesList(true);
    }
  }

  /* Fetch Switcher Clubs + Company Defaults*/
  useEffect(() => {
    if (companyId) {
      fetchSwitcherClubs(companyId);
      fetchCompanyDefaults(companyId);
    }
  }, [companyId]);

  /* Set Selected Club */
  useEffect(() => {
    if (switcherClubs?.length > 0) {

      const selectedClubLocal = JSON.parse(
          localStorage.getItem('selectedClub'));

      if (selectedClubLocal) {

        const localClub = switcherClubs.find((club) => {
          return club.clubId === selectedClubLocal.id;
        });

        const localClubParsed = {
          id: localClub?.clubId,
          timezone: localClub?.addresses[0].timezone,
          name: localClub?.name,
          imageUrl: localClub?.imageUrl,
          email: localClub?.email,
        };
        dispatch(setSelectedClub(localClubParsed));
      }

      if (!selectedClubLocal) {
        const defaultClub = {
          id: switcherClubs[0]?.clubId,
          timezone: switcherClubs[0]?.addresses[0].timezone,
          name: switcherClubs[0]?.name,
          imageUrl: switcherClubs[0]?.imageUrl,
          email: switcherClubs[0]?.email,
        };
        dispatch(setSelectedClub(defaultClub));
      }
      setHasSetSwitcherClub(true);
    }
  }, [switcherClubs]);

  /*Fetch Club Policies*/
  useEffect(() => {
    if (clubId) {
      fetchClubPolicies(clubId);
      if (CAN_READ_INTEGRATIONS) {
        fetchClubIntegrations(clubId);
      }
    }
  }, [clubId]);

  /*Fetch Countries*/
  useEffect(() => {
    fetchCountriesList();
  }, []);

  /*Set Company and Club Identification Request Headers*/
  useEffect(() => {
    if (hasSetSwitcherClub && companyId && clubId) {
      axios.defaults.headers.common['x-clubos-company'] = companyId;
      axios.defaults.headers.common['x-clubos-club'] = clubId;
      setHasSetHeaders(true);
    }
  }, [hasSetSwitcherClub, companyId, clubId]);

  /*Cleanup Company and Club Identification Request Headers*/
  useEffect(() => {
    return () => {
      delete axios.defaults.headers.common['x-clubos-company'];
      delete axios.defaults.headers.common['x-clubos-club'];
    };
  }, []);

  if (isLoading) {
    return <Box
        sx={{
          width: '100vw',
          height: '100vh',
          position: 'relative',
        }}
    >
      <LinearProgress sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        left: 0,
      }}/>

    </Box>;
  }

  if (!isLoading) {
    return <Outlet/>;
  }

}

export default InitClubManagers;

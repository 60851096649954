export default function DialogOverride(theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paperWidthSm': {
            borderRadius: '12px',
            width: '600px',
          },
          '.MuiDialog-paperWidthXl': {
            borderRadius: '12px',
          },
        },
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
        sx: {
          padding: '24px',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 26px',
          borderBottom: 'none',
        },
      },
      defaultProps: {
        dividers: true,
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: '20px',
          paddingBottom: '24px',
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },

  };
}

function PlumpyPackages(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M5,19h14c1.657,0,3-1.343,3-3V6c0-1.657-1.343-3-3-3H5C3.343,3,2,4.343,2,6v10C2,17.657,3.343,19,5,19z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M11.471,12.001L11.673,12H22v-2h-6.414C15.844,9.544,16,9.039,16,8.5C16,6.374,14.626,5,12.5,5c-1.131,0-1.944,0.551-2.5,1.33C9.444,5.551,8.631,5,7.5,5C5.374,5,4,6.374,4,8.5C4,9.04,4.158,9.544,4.417,10H2v2h5.977c-1.212,2.181-0.433,3.958,0.316,4.707c0.193,0.193,0.444,0.289,0.695,0.289c0.25,0,0.501-0.095,0.697-0.282c0.393-0.377,0.404-1.007,0.035-1.407c-0.09-0.098-0.636-0.762-0.202-1.902C10.509,15.401,12.693,16,14,16c0.55,0,0.994-0.444,0.997-0.994s-0.441-1-0.992-1.006c-0.434-0.005-2.364-0.137-2.877-2c0.023,0,0.043,0.002,0.066,0.002C11.284,12.002,11.376,12.002,11.471,12.001z M12.5,7C12.871,7,14,7,14,8.5c0,0.812-0.973,1.499-2.125,1.499L11.666,10h-0.663C11.001,9.932,11,9.849,11,9.749C11,8.888,11.378,7,12.5,7z M8.125,9.999C6.973,9.999,6,9.312,6,8.5C6,7,7.129,7,7.5,7C8.622,7,9,8.888,9,9.749C9,9.849,8.999,9.932,8.997,10H8.334L8.125,9.999z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyPackages;

import {createContext, useContext, useState} from 'react';

export const UISettingsContext = createContext();

function ProviderUISettings({children}) {

  const defaults = {
    layout: 'horizontal',
    collapsed: false,
  };

  const [uiSettings, setUiSettings] = useState(() => {

    const localDefaults = localStorage.getItem('uiSettings');

    if (localDefaults) {
      const parsedLocalDefaults = JSON.parse(localDefaults);
      //Hardcoding layout to horizontal, might be changed in future
      parsedLocalDefaults.layout = defaults.layout;
      return parsedLocalDefaults;
    }else {
      return defaults;
    }
  });

  function setLayout(layout) {

    const newState = {
      ...uiSettings,
      layout: layout,
    };

    localStorage.setItem('uiSettings', JSON.stringify(newState));
    setUiSettings(newState);
  }

  function setCollapsed(collapsed) {
    const newState = {
      ...uiSettings,
      collapsed: collapsed,
    };

    localStorage.setItem('uiSettings', JSON.stringify(newState));
    setUiSettings(newState);
  }

  return (
      <UISettingsContext.Provider value={{
        layout: uiSettings.layout,
        collapsed: uiSettings.collapsed,
        setLayout,
        setCollapsed,
      }}>
        {children}
      </UISettingsContext.Provider>
  );

}

export function useUiSettings() {

  const {layout, collapsed, setLayout, setCollapsed} = useContext(
      UISettingsContext);

  return {
    layout,
    collapsed,
    setLayout,
    setCollapsed,
  };
}

export default ProviderUISettings;

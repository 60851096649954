import {Box} from '@mui/material';
import LayoutMainHeaderClubSelect from '../LayoutMainHeaderClubSelect';
import {LAYOUT_MAIN__HEADER__TOP__HEIGHT} from '../../../constants/ui';
import ContainerFullWidth
  from '../../../components/_Global/Containers/ContainerFullWidth';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import LayoutMainMobileDrawer from '../MobileDrawer/LayoutMainMobileDrawer';

function LayoutMainVerticalLayoutHeaderTop({role, disableDropdown}) {

  const {t} = useTranslate();

  const isMobile = useIsMobile();

  const [isZendeskOpen, setIsZendeskOpen] = useState(false);

  const openZendeskWidget = () => {
    if (typeof window.openCloseZendeskWidget === 'function') {
      window.openCloseZendeskWidget();
      setIsZendeskOpen((prevState) => !prevState);
    } else {
      console.error('Zendesk open function is not defined');
    }
  };

  return (
      <Box
          sx={{
            background: 'linear-gradient(90deg, #5263EA 0%, #CA3EDE 100%)',
          }}
      >
        <ContainerFullWidth>
          <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'space-between' : 'center',
                alignItems: 'center',
                height: LAYOUT_MAIN__HEADER__TOP__HEIGHT,
                position: 'relative',
              }}
          >

            <LayoutMainHeaderClubSelect
                disableDropdown={disableDropdown}
                size={'compact'}
                color={'inverted'}
            />

            {
                isMobile &&
                <LayoutMainMobileDrawer role={role}/>
            }


          </Box>
        </ContainerFullWidth>
      </Box>

  );
}

export default LayoutMainVerticalLayoutHeaderTop;

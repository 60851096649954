import {useEffect, useState} from 'react';
import {Box, Button, Stack, Tooltip, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import {useTranslate} from '@tolgee/react';
import {financialsList_GET} from '../../vendor/redux/actions/financials';
import ChipFinancialStatus
  from '../../components/Financials/ChipFinancialStatus';
import DataGridReusable from '../../components/_Global/DatGrid/DataGridReusable';
import {
  columnTypeDate,
  columnTypeDefaults,
  columnTypeNumber,
} from '../../vendor/mui/dataGridFilterColumnTypes';
import {
  getSingleSelectFilterOperators,
} from '../../vendor/mui/dataGridFilterOperators';
import {
  GridFilterFinancesTransactionPaymentGatewaySelect,
  GridFilterFinancesTransactionStatusSelect,
  GridFilterFinancesTransactionTypeSelect,
  GridFilterSportSelect,
} from '../../vendor/mui/dataGridFilterInputs';
import useDefaults from '../../hooks/defaults/useDefaults';
import useLocale from '../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../utils/localization';
import {useSelectedClub} from '../../hooks/clubs/useSelectedClub';
import {getId, getLinksStates, getTransactionLink} from '../../utils/finances';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import MobileCardsView
  from '../../components/_Global/MobileCardsView/MobileCardsView';
import useIsMobile from '../../hooks/ui/useIsMobile';
import {getTableValueInMobileView} from '../../utils/ui';

function PageFinancesTransactionsList({
                                        rangeValue,
                                        loadFullData = false,
                                        filterType = 'server',
                                        paginatonType = 'server',
                                      }) {

  const dispatch = useDispatch();
  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const {defaultCurrencySymbol} = useDefaults();
  const {locale} = useLocale();
  dayjs.locale(locale);

  const selectedClub = useSelectedClub();
  const clubId = selectedClub?.id;

  const [rows, setRows] = useState(null);
  const [isLoadingRows, setIsLoadingRows] = useState(false);
  const [paginationMode, setPaginationMode] = useState(paginatonType);
  const [filterMode, setFilterMode] = useState(filterType);
  const [pageState, setPageState] = useState(() => {
    return pageStateInitializer(searchParams);
  });

  function pageStateInitializer(searchParams) {

    const defaultPageSize = loadFullData ?
        10000 :
        searchParams.get('pageSize') || 50;
    const defaultPage = searchParams.get('page') ?
        searchParams.get('page') - 1 :
        0;

    return {
      isLoading: false,
      rows: null,
      total: 0,
      page: defaultPage,
      pageSize: defaultPageSize,
      searchTerm: '',
      startDate: '',
      endDate: '',
    };

  }

  function handlePaginationModelChange(model) {
    console.log('pagination model', model);
    if (paginationMode === 'server') {
      setPageState((prevState) => {
        return {
          ...prevState,
          page: model.page,
          pageSize: model.pageSize,
        };
      });
    }

  }

  function handleFilterModelChange(model) {
    const searchTerm = model?.quickFilterValues?.join(' ');
    setPageState((prevState) => {
      return {
        ...prevState,
        searchTerm,
      };
    });

  }

  const handleClickOnId = (row, MNPBooking, ID) => {

    if (MNPBooking || !ID)
      return;

    const toReturn = {
      pathname: `${getTransactionLink(row, row?.type)}`,
      state: getLinksStates(row) ?? '',
    };
    const state = getLinksStates(row);
    const url = state ?
        `${getTransactionLink(row, row?.type)}?id=${state?.id}&reRoute=true` :
        getTransactionLink(row, row?.type);
    window.open(url, '_blank');

  };

  const columns = [

    {
      ...columnTypeDefaults,
      ...columnTypeDate,
      field: 'updatedAt',
      flex: 1.2,
      headerName: t('finances.wallet.transactions.table.col.label.date'),
      valueGetter: (value, row) => {
        return dayjs(row?.updatedAt, 'YYYY-MM-DD').toDate();
      },
      valueFormatter: (value) => {
        const format = locale === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';
        return dayjs(value).format(format);
      },
      renderCell: ({row}) => {
        return dayjs(row?.updatedAt, 'YYYY-MM-DD').format('ddd ll');
      },

      // mobile cards view
      id: 1,
      order: 1,
      isExpandable: true,
      isParent: true,
      render: ({row}) => {
        return <Stack
            width={'100%'}
            direction={'row'}
            justifyContent={'space-between'}
        >
          <Typography
              variant={'body1'}
              fontWeight={500}
          >
            {t('finances.wallet.transactions.table.col.label.date')}:
          </Typography>
          <Button
              sx={{flexGrow: 1, padding: '0', justifyContent: 'flex-end'}}
              variant={'text'}
              color={'primary'}
              textTransform={'capitalize'}
          >
            {dayjs(row?.updatedAt, 'YYYY-MM-DD').format('ddd ll')}
          </Button>
        </Stack>;
      },
    },
    {
      // ...columnTypeDateTime,
      ...columnTypeDefaults,
      field: 'time',
      headerName: t('player.wallet.detail.transactions.table.header.time'),

      valueGetter: (value, row) => {
        return dayjs(row?.updatedAt).format('hh:mm A');

      },
      renderCell: ({row}) => {
        return dayjs(row?.updatedAt).format('hh:mm A');
      },

      // mobile cards view
      id: 2,
      order: 2,
      isChild: true,
      parentId: 1,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('player.wallet.detail.transactions.table.header.time'),
            dayjs(row?.updatedAt).format('hh:mm A'),
        );
      },
    },
    {
      ...columnTypeDefaults,
      flex: 1.7,
      resizable: true,
      field: 'email',
      headerName: t('authFlow.fields.labelEmail'),
      valueGetter: (value, row) => {
        return row?.email;
      },
      renderCell: ({row}) => {
        return row?.email || '-';
      },

      // mobile cards view
      id: 3,
      order: 3,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('authFlow.fields.labelEmail'),
            row?.email || '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      flex: 1,
      field: 'activity',
      headerName: t('finances.wallet.transactions.table.col.label.id'),
      valueGetter: (value, row) => {
        return getId(row, row?.type);
      },
      renderCell: ({row}) => {

        const MNPBooking = row?.channel == 'MEET&PLAY';
        const ID = getId(row, row?.type);
        const IDTag = !ID || MNPBooking ? Typography : Button;

        return (
            <Tooltip
                title={MNPBooking ?
                    `Meet And Play ${t(
                        'global.types.transactions.booking_creation')}` :
                    ''}
                placement="top"
                arrow="bottom"
            >
              <IDTag
                  sx={{width: '100%', textAlign: 'center'}}
                  variant="text"
                  component={'p'}
                  onClick={() => handleClickOnId(row, MNPBooking, ID)}
              >
                {
                  MNPBooking ?
                      'M&P' :
                      ID ?? '-'
                }
              </IDTag>
            </Tooltip>
        );
      },

      // mobile cards view
      id: 4,
      order: 4,
      render: ({row}) => {

        const MNPBooking = row?.channel == 'MEET&PLAY';
        const ID = getId(row, row?.type);
        const IDTag = !ID || MNPBooking ? Typography : Button;

        return (
            getTableValueInMobileView(
                t('finances.wallet.transactions.table.col.label.id'),
                MNPBooking ?
                    'M&P' :
                    ID ?? '-',
            )
        );
      },
    },
    {
      ...columnTypeDefaults,
      field: 'sports',
      headerName: t('pricing.sportsLabel'),
      filterOperators: getSingleSelectFilterOperators(
          GridFilterSportSelect),
      valueGetter: (value, row) => {
        return row?.sport;
      },
      renderCell: ({row}) => {
        return row?.sport || '-';

      },

      // mobile cards view
      id: 5,
      order: 5,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('pricing.sportsLabel'),
            row?.sport || '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      field: 'type',
      headerName: t('financials.table.header.type'),
      filterOperators: getSingleSelectFilterOperators(
          GridFilterFinancesTransactionTypeSelect),
      valueGetter: (value, row) => {
        return row.type;
      },
      renderCell: ({row}) => {
        return <Typography
            variant={'body2'}
            color={'text.primary'}
            textTransform={'capitalize'}
            lineHeight={3.8}
        >
          {row?.type?.replace(/[^a-zA-Z0-9 ]/g, ' ')}
        </Typography>;
      },

      // mobile cards view
      id: 6,
      order: 6,
      isHeaderField: true,
      isPrimary: true,
      render: ({row}) => {
        return getTableValueInMobileView(
            row.type,
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeNumber,
      field: 'amountCharged',
      headerName: t('financials.table.header.amountCharged'),
      valueGetter: (value, row) => {
        return +row.totalAmount;
      },
      valueFormatter: (value) => {
        return value ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +value)}` :
            '-';
      },
      renderCell: ({row}) => {
        return `${defaultCurrencySymbol}${getLocalizedNum(locale,
            +row.totalAmount)}`;
      },

      // mobile cards view
      id: 7,
      order: 7,
      isChild: true,
      parentId: 14,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('financials.table.header.amountCharged'),
            `${defaultCurrencySymbol}${getLocalizedNum(locale,
                +row.totalAmount)}`,
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeNumber,
      field: 'duration',
      headerName: t('schedule.duration'),
      valueGetter: (value, row) => {
        return row.totalMinutes;
      },
      valueFormatter: (value) => {
        return value ? `${value}` : '-';
      },
      renderCell: ({row}) => {
        return row?.totalMinutes ? `${row?.totalMinutes} Min` : '-';
      },

      // mobile cards view
      id: 8,
      order: 8,
      isChild: true,
      parentId: 1,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('schedule.duration'),
            row?.totalMinutes ? `${row?.totalMinutes} Min` : '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeDate,
      field: 'play date',
      flex: 2,
      headerName: t('daily.report.table.playDate'),
      valueGetter: (value, row) => {
        return dayjs(row?.playDate, 'YYYY-MM-DD').toDate();
      },

      valueFormatter: (value) => {
        const format = locale === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY';
        return value == 'Invalid Date' ? '' : dayjs(value).format(format);
      },
      renderCell: ({row}) => {
        return row?.playDate ?
            dayjs(row?.playDate, 'YYYY-MM-DD').format('ddd ll') :
            '-';
      },

      // mobile cards view
      id: 9,
      order: 9,
      isChild: true,
      parentId: 1,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('daily.report.table.playDate'),
            row?.playDate ?
                dayjs(row?.playDate, 'YYYY-MM-DD').format('ddd ll') :
                '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeNumber,
      flex: 1.3,
      field: 'refundedAmount',
      headerName: t('financials.table.header.refundedAmount'),
      valueGetter: (value, row) => {
        return +row?.refundedAmount;
      },
      valueFormatter: (value) => {
        return value ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +value)}` :
            '';
      },
      renderCell: ({row}) => {
        return row?.refundedAmount ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale,
                +row?.refundedAmount)}` :
            '-';
      },

      // mobile cards view
      id: 10,
      order: 10,
      isChild: true,
      parentId: 14,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('financials.table.header.refundedAmount'),
            row?.refundedAmount ?
                `${defaultCurrencySymbol}${getLocalizedNum(locale,
                    +row?.refundedAmount)}` :
                '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeNumber,
      field: 'revenue',
      headerName: t('financials.table.header.revenue'),
      valueGetter: (value, row) => {
        return row?.revenue;
      },
      valueFormatter: (value) => {
        return value ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +value)}` :
            '';
      },
      renderCell: ({row}) => {
        return row?.revenue ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +row.revenue)}` :
            '-';
      },

      // mobile cards view
      id: 11,
      order: 11,
      isChild: true,
      parentId: 14,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('financials.table.header.revenue'),
            row?.revenue ?
                `${defaultCurrencySymbol}${getLocalizedNum(locale,
                    +row.revenue)}` :
                '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeNumber,
      field: 'platformFee',
      headerName: t('financials.table.header.playerFee'),
      valueGetter: (value, row) => {
        return +row.platformFee;
      },
      valueFormatter: (value) => {
        return value ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +value)}` :
            '';
      },
      renderCell: ({row}) => {
        return row?.platformFee ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale,
                +row.platformFee)}` :
            '-';
      },

      // mobile cards view
      id: 12,
      order: 12,
      isChild: true,
      parentId: 14,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('financials.table.header.playerFee'),
            row?.platformFee ?
                `${defaultCurrencySymbol}${getLocalizedNum(locale,
                    +row.platformFee)}` :
                '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      ...columnTypeNumber,
      field: 'clubFee',
      headerName: t('financials.table.header.clubFee'),
      valueGetter: (value, row) => {
        return +row?.clubFee;
      },
      valueFormatter: (value) => {
        return value ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +value)}` :
            '';
      },
      renderCell: ({row}) => {
        return row?.clubFee ?
            `${defaultCurrencySymbol}${getLocalizedNum(locale, +row.clubFee)}` :
            '-';
      },

      // mobile cards view
      id: 13,
      order: 13,
      isChild: true,
      parentId: 14,
      render: ({row}) => {
        return getTableValueInMobileView(
            t('financials.table.header.clubFee'),
            row?.clubFee ?
                `${defaultCurrencySymbol}${getLocalizedNum(locale,
                    +row.clubFee)}` :
                '-',
        );
      },
    },
    {
      ...columnTypeDefaults,
      field: 'paymentGateway',
      headerName: t('financials.table.header.paymentGateway'),
      filterOperators: getSingleSelectFilterOperators(
          GridFilterFinancesTransactionPaymentGatewaySelect),
      valueGetter: (value, row) => {
        return row.paymentGateway;
      },
      renderCell: ({row}) => {
        return <Typography
            variant={'body2'}
            color={'text.primary'}
            textTransform={'capitalize'}
            lineHeight={3.8}
        >
          {row.paymentGateway}
        </Typography>;
      },

      // mobile cards view
      id: 14,
      order: 14,
      isParent: true,
      isExpandable: true,
      render: ({row}) => {
        return (
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'space-between'}
            >
              {getTableValueInMobileView(
                  `${t('financials.table.header.paymentGateway')}:`,
              )}
              <Button
                  sx={{padding: '0', justifyContent: 'flex-end'}}
                  variant={'text'}
                  color={'primary'}
                  textTransform={'capitalize'}
              >
                {row?.paymentGateway}
              </Button>
            </Stack>
        );
      },
    },
    {
      ...columnTypeDefaults,
      field: 'status',
      flex: 1.5,
      headerName: t('financials.table.header.status'),
      filterOperators: getSingleSelectFilterOperators(
          GridFilterFinancesTransactionStatusSelect),
      valueGetter: (value, row) => {
        return t(`global.types.transactions.status.${row.status}`);
      },
      renderCell: ({row}) => {
        return <ChipFinancialStatus
            status={row?.status}
        />;
      },

      // mobile cards view
      id: 15,
      order: 15,
      isHeaderField: true,
      isSubPrimary: true,
      render: ({row}) => {
        return <ChipFinancialStatus
            status={row?.status}
        />;
      },
    },
  ];

  function fetchAndSetRows(startDate, endDate) {

    startDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
    endDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';

    setPageState((prev) => {
      return {...prev, isLoading: true};
    });

    dispatch(
        financialsList_GET(pageState?.page, pageState?.pageSize,
            pageState?.searchTerm, startDate, endDate, cbSuccess,
            cbFailure),
    );

    function cbSuccess(res) {
      console.log('financialsList_GET Success', res);
      setPageState((prev) => {
        return {
          ...prev,
          rows: res?.data?.data?.rows,
          total: res?.data?.data?.count,
          isLoading: false,
        };
      });
    }

    function cbFailure(res) {
      console.log('financialsList_GET Fail', res);
      setPageState((prev) => {
        return {...prev, isLoading: false};
      });
    }
  }

  function getRowId(row) {
    return row.financialId;
  }

  const getMobileCardActions = ({row}) => {

    const MNPBooking = row?.channel == 'MEET&PLAY';
    const ID = getId(row, row?.type);
    const showBtn = ID && !MNPBooking;

    if (showBtn) {
      return (
          <>
            <Button
                size="small"
                disabled={pageState?.isLoading}
                onClick={() => handleClickOnId(row, MNPBooking, ID)}
            >
              {t('buttons.viewDetails')}
            </Button>
          </>
      );
    }
  };

  useEffect(() => {

    const timeout = setTimeout(() => {
      fetchAndSetRows();
    }, 400);
    return () => {
      clearTimeout(timeout);
    };

  }, [clubId, pageState.page, pageState.pageSize, pageState.searchTerm]);

  useEffect(() => {

    if (rangeValue[0] && rangeValue[1]) {
      fetchAndSetRows(rangeValue[0], rangeValue[1]);
    }

  }, [rangeValue]);

  return (
      <Box>
        <Box sx={{mb: 4}}>
          {isMobile ? (
              <MobileCardsView
                  exportFileName={`${t('sideBar.link.textFinance')}`}
                  columns={columns}
                  pageState={pageState}
                  setPageState={setPageState}
                  pagination="server"
                  Actions={({row}) => getMobileCardActions({row})}
              />
          ) : (
              <DataGridReusable
                  getRowId={getRowId}
                  exportFileName="Finance"
                  isLoading={pageState.isLoading}
                  rows={pageState.rows}
                  {...(!loadFullData && {rowCount: pageState.total})}
                  columns={columns}
                  defaultPageSize={loadFullData ? 50 : pageState.pageSize}
                  disableExport={loadFullData ? false : true}
                  disableFilters={loadFullData ? false : true}
                  paginationMode={paginationMode}
                  filterMode={filterMode}
                  onPaginationModelChange={handlePaginationModelChange}
                  onFilterModelChange={handleFilterModelChange}
              />
          )}
        </Box>
      </Box>
  );
}

export default PageFinancesTransactionsList;

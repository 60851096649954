import {shadows} from '../shadows';

import { alpha } from "@mui/material/styles"
import { listClasses } from "@mui/material/List"
import { paperClasses } from "@mui/material/Paper"
import { buttonClasses } from "@mui/material/Button"
import { listItemIconClasses } from "@mui/material/ListItemIcon"
import { tablePaginationClasses } from "@mui/material/TablePagination"
//
import { paper } from "../css"

// ----------------------------------------------------------------------

export default function DataGridOverride(theme) {

  const paperStyles = paper({ theme, dropdown: true })

  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderWidth: 0,
          // backgroundColor: '#FFFFFF',
          // borderRadius: '8px',
          // boxShadow: shadows('light')[8],
          [`& .${tablePaginationClasses.root}`]: {
            borderTop: 0
          },
          [`& .${tablePaginationClasses.toolbar}`]: {
            height: "auto"
          },

          '.MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within,.MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },

          '.MuiDataGrid-footerContainer': {
            minHeight: '64px',
            '.MuiTablePagination-root': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          },

          '.MuiTablePagination-root': {
            '.MuiTablePagination-spacer': {
              display: 'none',
            },
            '.MuiToolbar-root': {
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              paddingRight: '24px !important',
            },
            '.MuiTablePagination-displayedRows': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            ".MuiTablePagination-input":{
              "svg":{
                right:'3px',
                top:'calc(50% - 10px)',
              }
            },
            'div[class*=--pinnedLeft]': {
              borderRight: 'none',
              backgroundColor: 'inherit',

              [theme.breakpoints.down('md')]: {
                backgroundColor: '#fff',
              },
            },
          },
        },
        cell: {
          borderBottom: `1px dashed ${theme.palette.divider}`,
          color: theme.palette.text.primary,
        },
        selectedRowCount: {
          whiteSpace: "nowrap"
        },
        columnSeparator: {
          color: theme.palette.divider
        },
        toolbarContainer: {
          padding: theme.spacing(2),
          borderBottom: `1px dashed ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.neutral
        },
        paper: {
          ...paperStyles,
          padding: 0
        },
        menu: {
          [`& .${paperClasses.root}`]: {
            ...paperStyles
          },
          [`& .${listClasses.root}`]: {
            padding: 0,
            [`& .${listItemIconClasses.root}`]: {
              minWidth: 0,
              marginRight: theme.spacing(2)
            }
          }
        },
        columnHeaders: {
          borderRadius: 0,
          backgroundColor: theme.palette.background.neutral,
          '.MuiDataGrid-row--borderBottom': {
            background: 'none !important',
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            ".MuiDataGrid-columnHeaderTitle" :{
              fontWeight: '600 !important',
            }
          },
        },
        panelHeader: {
          padding: theme.spacing(2)
        },
        panelFooter: {
          padding: theme.spacing(2),
          justifyContent: "flex-end",
          borderTop: `dashed 1px ${theme.palette.divider}`,
          [`& .${buttonClasses.root}`]: {
            "&:first-of-type": {
              border: `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`
            },
            "&:last-of-type": {
              marginLeft: theme.spacing(1.5),
              color: theme.palette.background.paper,
              backgroundColor: theme.palette.text.primary
            }
          }
        },
        filterForm: {
          padding: theme.spacing(2)
        },
        filterFormValueInput: {
          marginLeft: theme.spacing(2)
        },
        filterFormColumnInput: {
          marginLeft: theme.spacing(2)
        },
        filterFormOperatorInput: {
          marginLeft: theme.spacing(2)
        }
      }
    }
  }
}



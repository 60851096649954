import {Outlet} from 'react-router-dom';
import LayoutMainVerticalLayoutHeader
  from './VerticalLayoutHeader/LayoutMainVerticalLayoutHeader';
import LayoutMainPage from './Page/LayoutMainPage';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {Suspense, useEffect, useState} from 'react';
import SuspensePageLoadFallback
  from '../../components/_Global/Fallback/SuspensePageLoadFallback';
import LayoutMainHorizontalLayoutSidebar
  from './HorizontalLayoutSidebar/LayoutMainHorizontalLayoutSidebar';
import LayoutMainHorizontalLayoutHeader
  from './HorizontalLayoutHeader/LayoutMainHorizontalLayoutHeader';
import {useUiSettings} from '../../providers/ProvideUISettings';


function LayoutMain({role}) {

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const {layout} = useUiSettings();

  const isHorizontalLayout = isLgUp && layout === 'horizontal';

  // useEffect(() => {
  //   document.body.addEventListener('wheel', () => {
  //     document?.activeElement?.blur();
  //   });
  //   document.body.addEventListener('scroll', () => {
  //     document?.activeElement?.blur();
  //   });
  // }, []);

  return (
      <Box
          sx={{
            backgroundColor: theme.palette.background.neutral,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            position: 'relative',
          }}
      >
        {
            !isHorizontalLayout &&
            <LayoutMainVerticalLayoutHeader role={role}/>
        }
        <Box
            sx={{
              zIndex: 1,
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
            }}
        >
          {
              isHorizontalLayout &&
              <LayoutMainHorizontalLayoutSidebar
                  role={role}
              />
          }
          <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                minWidth: 0,
              }}
          >
            {
                isHorizontalLayout &&
                <LayoutMainHorizontalLayoutHeader/>
            }
            <Suspense fallback={<SuspensePageLoadFallback/>}>
              <LayoutMainPage>
                <Outlet/>
              </LayoutMainPage>
            </Suspense>
          </Box>
        </Box>
      </Box>
  );
}

export default LayoutMain;

import {LayoutMainNavHorizontal} from '../Nav/LayoutMainNavHorizontal';
import useNavItems from '../Nav/useNavItems';
import {LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT} from '../../../constants/ui';
import {Box, Stack, useTheme} from '@mui/material';
import ContainerFullWidth
  from '../../../components/_Global/Containers/ContainerFullWidth';
import LayoutMainHeaderActions from '../LayoutMainHeaderActions';

function LayoutMainVerticalLayoutHeaderBottom({role}) {

  const theme = useTheme();
  const navItems = useNavItems(role);

  return (
      <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: '#fff',
            height: LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
            display: 'flex',
            alignItems: 'center',
          }}
      >
        <ContainerFullWidth>
          <Stack
              direction={'row'}
              justifyContent={'space-between'}
          >
            <LayoutMainNavHorizontal navItems={navItems}/>
            <LayoutMainHeaderActions/>
          </Stack>

        </ContainerFullWidth>
      </Box>
  );

}

export default LayoutMainVerticalLayoutHeaderBottom;

function PlumpyCompany(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path d="M19,8h-7V5c0-1.105-0.895-2-2-2H4C2.895,3,2,3.895,2,5v14c0,1.105,0.895,2,2,2h8v-1h7c1.105,0,2-0.895,2-2v-8C21,8.895,20.105,8,19,8z" opacity=".35" fill="currentColor" />
          <rect width="2" height="2" x="4" y="5" fill="currentColor" />
          <rect width="2" height="2" x="8" y="5" fill="currentColor" />
          <rect width="2" height="2" x="4" y="9" fill="currentColor" />
          <rect width="2" height="2" x="8" y="9" fill="currentColor" />
          <rect width="2" height="2" x="4" y="13" fill="currentColor" />
          <rect width="2" height="2" x="8" y="13" fill="currentColor" />
          <rect width="2" height="2" x="4" y="17" fill="currentColor" />
          <rect width="2" height="2" x="8" y="17" fill="currentColor" />
          <path d="M20,7h-8v14h8c1.105,0,2-0.895,2-2V9C22,7.895,21.105,7,20,7z M16,15h-2v-2h2V15z M16,11h-2V9h2V11z M20,15h-2v-2h2V15z M16,19h-2v-2h2V19z M20,19h-2v-2h2V19z M20,11h-2V9h2V11z" fill="currentColor" />
      </svg>
  );
}

export default PlumpyCompany;

export default function TypographyOverride(theme) {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // currently, you need to provide all of the default mapping because it will replace, not merge.
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          body1: 'p',
          body2: 'p',
          inherit: 'p',
          // You have to add this line
          // otherwise the `span` (display `inline` by default)
          // cannot have margin.
          'h7': 'h6',
          'h5-bold': 'h5',
          'h6-bold': 'h6',
        },
      },
    },
  };
}

function PlumpyReports(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path d="M22,13h-6V5c0-1.105,0.895-2,2-2h2c1.105,0,2,0.895,2,2V13z"
                opacity=".35" fill="currentColor"/>
          <path d="M16,13h6v6c0,1.105-0.895,2-2,2h-2c-1.105,0-2-0.895-2-2V13z"
                fill="currentColor"/>
          <path d="M15,16H9v-6c0-1.105,0.895-2,2-2h2c1.105,0,2,0.895,2,2V16z"
                opacity=".35" fill="currentColor"/>
          <path d="M9,16h6v3c0,1.105-0.895,2-2,2h-2c-1.105,0-2-0.895-2-2V16z"
                fill="currentColor"/>
          <path d="M8,12H2V7c0-1.105,0.895-2,2-2h2c1.105,0,2,0.895,2,2V12z"
                opacity=".35" fill="currentColor"/>
          <path d="M2,12h6v7c0,1.105-0.895,2-2,2H4c-1.105,0-2-0.895-2-2V12z"
                fill="currentColor"/>
      </svg>
  );
}

export default PlumpyReports;

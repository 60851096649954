function PlumpyHelp(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <circle cx="12" cy="12" r="10" opacity=".35" fill="currentColor"/>
          <path
              d="M11.883,13.973h-0.006c-0.67,0-1.187-0.605-1.072-1.265c0.35-2.013,1.917-2.083,1.917-3.333c0-0.347-0.06-1.124-0.896-1.124c-0.452,0-0.718,0.289-0.873,0.597c-0.209,0.417-0.691,0.622-1.15,0.541l0,0C9.107,9.265,8.68,8.519,8.975,7.877C9.397,6.96,10.261,6,11.972,6c2.776,0,3.148,2.174,3.148,3.196c0,2.416-1.799,2.506-2.172,3.898C12.813,13.596,12.403,13.973,11.883,13.973z M13.325,16.533c0,0.412-0.127,0.76-0.382,1.043C12.687,17.858,12.352,18,11.94,18c-0.414,0-0.748-0.142-1.003-0.424c-0.255-0.283-0.384-0.631-0.384-1.043c0-0.403,0.129-0.75,0.384-1.045c0.255-0.293,0.589-0.439,1.003-0.439c0.412,0,0.747,0.146,1.003,0.439C13.198,15.783,13.325,16.131,13.325,16.533z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyHelp;

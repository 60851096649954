function PlumpyPolicies(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M20,4h-7.184C12.928,4.314,13,4.647,13,5s-0.072,0.686-0.184,1H20c0.552,0,1-0.448,1-1C21,4.448,20.552,4,20,4z"
              fill="currentColor"/>
          <path
              d="M4,6h3.184C7.072,5.686,7,5.353,7,5s0.072-0.686,0.184-1H4C3.448,4,3,4.448,3,5C3,5.552,3.448,6,4,6z"
              fill="currentColor"/>
          <circle cx="10" cy="5" r="3" opacity=".35" fill="currentColor"/>
          <path
              d="M20,11h-2.184C17.928,11.314,18,11.647,18,12s-0.072,0.686-0.184,1H20c0.552,0,1-0.448,1-1C21,11.448,20.552,11,20,11z"
              fill="currentColor"/>
          <path
              d="M4,13h8.184C12.072,12.686,12,12.353,12,12s0.072-0.686,0.184-1H4c-0.552,0-1,0.448-1,1C3,12.552,3.448,13,4,13z"
              fill="currentColor"/>
          <circle cx="15" cy="12" r="3" opacity=".35" fill="currentColor"/>
          <path
              d="M20,18h-7.184C12.928,18.314,13,18.647,13,19s-0.072,0.686-0.184,1H20c0.552,0,1-0.448,1-1C21,18.448,20.552,18,20,18z"
              fill="currentColor"/>
          <path
              d="M4,20h3.184C7.072,19.686,7,19.353,7,19s0.072-0.686,0.184-1H4c-0.552,0-1,0.448-1,1C3,19.552,3.448,20,4,20z"
              fill="currentColor"/>
          <circle cx="10" cy="19" r="3" opacity=".35" fill="currentColor"/>
      </svg>
  );
}

export default PlumpyPolicies;

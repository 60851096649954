import {alpha} from '@mui/material';

const GREY = {
  0: '#FFFFFF',
  50: '#F9FAFB',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  grey: GREY,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export const PALETTE = {
  ...COMMON,
  primary: {
    main: '#166DE1',
    dark: '#0B3EA2',
    light: '#70B5F6',
  },
  secondary: {
    main: '#C53FDE',
    dark: '#771F9F',
    light: '#F48BF5',
  },
  error: {
    main: '#EB495C',
    dark: '#A9244C',
    light: '#F37578',
  },
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF',
    neutral: "#F3F7F9",
  },
  action: {
    ...COMMON.action,
    active: GREY[600],
  },
};

function PlumpyAdmins(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
        <rect width="5" height="4" x="13" y="17" opacity=".35" fill="currentColor"/>
        <circle cx="12" cy="7" r="5" opacity=".35" fill="currentColor"/>
        <path
            d="M17.892,15H7.001c-2.344,0-3.671,0.981-3.944,2.406C2.689,19.323,4.149,21,6,21h8v-2c0-0.552,0.448-1,1-1h2c0.552,0,1,0.448,1,1v2c1.851,0,3.311-1.677,2.943-3.594C20.67,15.981,19.343,15,17.892,15z"
            fill="currentColor"/>
      </svg>
  );
}

export default PlumpyAdmins;

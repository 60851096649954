function IconInfo() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 16.8307C12.9357 16.8307 16.6667 13.0998 16.6667 8.4974C16.6667 3.89502 12.9357 0.164062 8.33333 0.164062C3.73096 0.164062 0 3.89502 0 8.4974C0 13.0998 3.73096 16.8307 8.33333 16.8307Z"
        fill="#E44545"
      />
      <path
        d="M8.33268 5.6849C8.90798 5.6849 9.37435 5.21853 9.37435 4.64323C9.37435 4.06793 8.90798 3.60156 8.33268 3.60156C7.75739 3.60156 7.29102 4.06793 7.29102 4.64323C7.29102 5.21853 7.75739 5.6849 8.33268 5.6849Z"
        fill="white"
      />
      <path
        d="M8.33268 13.3932C8.11167 13.3932 7.89971 13.3054 7.74343 13.1492C7.58715 12.9929 7.49935 12.7809 7.49935 12.5599V8.39323C7.27834 8.39323 7.06637 8.30543 6.91009 8.14915C6.75381 7.99287 6.66602 7.78091 6.66602 7.5599C6.66602 7.33888 6.75381 7.12692 6.91009 6.97064C7.06637 6.81436 7.27834 6.72656 7.49935 6.72656H8.33268C8.5537 6.72656 8.76566 6.81436 8.92194 6.97064C9.07822 7.12692 9.16602 7.33888 9.16602 7.5599V12.5599C9.16602 12.7809 9.07822 12.9929 8.92194 13.1492C8.76566 13.3054 8.5537 13.3932 8.33268 13.3932Z"
        fill="white"
      />
      <path
        d="M9.16602 13.3932H7.49935C7.27834 13.3932 7.06637 13.3054 6.91009 13.1492C6.75381 12.9929 6.66602 12.7809 6.66602 12.5599C6.66602 12.3389 6.75381 12.1269 6.91009 11.9706C7.06637 11.8144 7.27834 11.7266 7.49935 11.7266H9.16602C9.38703 11.7266 9.59899 11.8144 9.75527 11.9706C9.91155 12.1269 9.99935 12.3389 9.99935 12.5599C9.99935 12.7809 9.91155 12.9929 9.75527 13.1492C9.59899 13.3054 9.38703 13.3932 9.16602 13.3932Z"
        fill="white"
      />
    </svg>
  );
}

export default IconInfo;

import {Box, Divider, Drawer, Stack, Typography} from '@mui/material';
import {Menu} from '@mui/icons-material';
import LayoutMainHeaderLogo
  from '../LayoutMainHeaderLogo';
import SiteUserProfile from '../../../components/_Global/Site/SiteUserProfile';
import ProfileImageViewer
  from '../../../components/_Global/Profile/ProfileImageViewer';
import SiteLanguageSwitcher
  from '../../../components/_Global/Site/SiteLanguageSwitcher';
import {useEffect, useState} from 'react';
import {useSelectedClub} from '../../../hooks/clubs/useSelectedClub';
import {useLocation} from 'react-router-dom';
import useNavItems from '../Nav/useNavItems';
import LayoutMainNavVertical from '../Nav/LayoutMainNavVertical';

function LayoutMainMobileDrawer({role}) {

  const url = useLocation();
  const selectedClub = useSelectedClub();

  const navItems = useNavItems(role);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (newOpen) => {
    setDrawerOpen(newOpen);
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [url]);

  return (
      <>
        <Box
            onClick={() => toggleDrawer(true)}
            sx={{
              color: '#fff',
              cursor: 'pointer',
              display: 'flex',
            }}
        >
          <Menu
              fontSize="medium"
              color={'inherit'}
          />
        </Box>

        <Drawer
            anchor={'right'}
            open={drawerOpen}
            onClose={() => toggleDrawer(false)}
            PaperProps={{
              sx: { width: '75vw' }
            }}
        >
          {
              selectedClub &&
              <MobileDrawerHeader
                  role={role}
                  toggleDrawer={toggleDrawer}
              />
          }
          {
              !selectedClub &&
              <Stack sx={{
                maxWidth: '75vw',
                mt: '1rem',
                padding: '5px 15px',
              }}>
                <LayoutMainHeaderLogo/>
              </Stack>
          }
          <Divider/>
          <Box
              p={1}
          >
            <LayoutMainNavVertical navItems={navItems}/>
          </Box>
        </Drawer>
      </>
  );

}

function MobileDrawerHeader({role, toggleDrawer}) {

  const selectedClub = useSelectedClub();

  return (
      <Stack>
        <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{maxWidth: '75vw', my: '1rem', padding: '0px 15px'}}
        >
          <>
            <ProfileImageViewer
                size={'xs'}
                imgPath={selectedClub?.imageUrl}
                placeholderStyle={'shield-icon'}
            />
            <Typography>
              {selectedClub?.name}
            </Typography>
          </>
          <>
            <SiteLanguageSwitcher onSuccess={() => toggleDrawer(false)}/>
          </>
        </Stack>
        <Divider/>
        <Box sx={{p: '1rem 12px'}}>
          <SiteUserProfile role={role}/>
        </Box>
      </Stack>
  );
}

export default LayoutMainMobileDrawer;


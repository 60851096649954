function PlumpySchedule(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  {...props} width={24} >
        <circle cx="14" cy="18" r="1" fill="currentColor"/>
        <circle cx="10" cy="18" r="1" fill="currentColor"/>
        <circle cx="6" cy="18" r="1" fill="currentColor"/>
        <circle cx="6" cy="14" r="1" fill="currentColor"/>
        <circle cx="10" cy="14" r="1" fill="currentColor"/>
        <circle cx="14" cy="14" r="1" fill="currentColor"/>
        <circle cx="18" cy="14" r="1" fill="currentColor"/>
        <circle cx="18" cy="10" r="1" fill="currentColor"/>
        <circle cx="14" cy="10" r="1" fill="currentColor"/>
        <circle cx="10" cy="10" r="1" fill="currentColor"/>
        <path
            d="M9.578,7C8.806,8.764,7.049,10,5,10c-0.712,0-1.387-0.154-2-0.422V18c0,1.657,1.343,3,3,3h12c1.657,0,3-1.343,3-3V7H9.578z"
            opacity=".35" fill="currentColor"/>
        <path
            d="M18,3H9.578C9.846,3.613,10,4.288,10,5S9.846,6.387,9.578,7C8.806,8.764,7.049,10,5,10c-0.712,0-1.387-0.154-2-0.422v2.128C3.634,11.895,4.305,12,5,12c3.17,0,5.845-2.109,6.706-5H21V6C21,4.343,19.657,3,18,3z"
            fill="currentColor"/>
        <circle cx="5" cy="5" r="5" opacity=".35" fill="currentColor"/>
        <path
            d="M7.13,6.07L5.968,4.619L5.75,2C5.72,1.64,5.434,1.344,5.063,1.313C4.648,1.278,4.285,1.586,4.25,2L4,5L3.998,5.027c-0.019,0.253,0.083,0.51,0.295,0.68L6.07,7.13c0.27,0.216,0.662,0.225,0.943,0C7.338,6.87,7.391,6.395,7.13,6.07z"
            fill="currentColor"/>
      </svg>
  );
}

export default PlumpySchedule;

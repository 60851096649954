function PlumpyPricing(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M18,2h-4.172C12.76,2,11.756,2.416,11,3.171l-7.975,7.975C2.364,11.808,2,12.687,2,13.621c0,0.935,0.364,1.814,1.025,2.475l4.879,4.879C8.565,21.636,9.444,22,10.379,22c0.935,0,1.814-0.364,2.475-1.025L20.828,13C21.584,12.245,22,11.24,22,10.172V6C22,3.794,20.206,2,18,2z M17,9c-1.105,0-2-0.896-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C19,8.104,18.105,9,17,9z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M13.512,10.488c-0.651-0.651-1.706-0.651-2.357,0c-0.325,0.326-2.341,2.341-2.667,2.667c-0.651,0.651-0.651,1.706,0,2.357c0.651,0.651,1.706,0.651,2.357,0c0.325-0.325,2.341-2.341,2.667-2.667C14.163,12.194,14.163,11.139,13.512,10.488z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyPricing;

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  CustomTablePagination,
} from '../../../vendor/mui/styled-components/MobileCardsView';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {SaveAlt, Search} from '@mui/icons-material';
import {clientSidePagination} from '../../../utils/mobileCardsView';
import {useTranslate} from '@tolgee/react';
import MobileListCard from '../MobileListCard/MobileListCard';
import {useSnackbar} from 'notistack';
import {downloadCSV} from '../../../utils/csv';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';

const MobileCardsView = (props) => {
  const {
    columns,
    pageState,
    setPageState,
    pagination = 'server',
    apiCall,
    handleRowClick,
    defaultExpanded = true,
    Actions,
    exportFileName = 'palla-app',
  } = props;

  // page State keys
  // pageState={
  //   rows:[],
  //   page:Number,
  //   pageSize:Number,
  //   isLoading:Boolean,
  //   total:Number,

  // keys below used on client side pagination tables when we
  // use search, pageChange or pageSize change

  //   updated:Boolean,
  //   filteredData:Boolean
  // }

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const rowsPerPageOption = pagination == 'server' ?
      [10, 20, 30, 50, 100] :
      [5, 10, 20, 30, 50, 100, {label: 'All', value: -1}];

  const handleChangePage = (event, newPage) => {
    setPageState({...pageState, page: newPage});
    if (pagination != 'server') {
      clientSidePagination({...pageState, page: newPage}, setPageState);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPageState({...pageState, pageSize: parseInt(event.target.value, 10)});
    if (pagination != 'server') {
      clientSidePagination(
          {...pageState, pageSize: parseInt(event.target.value, 10)},
          setPageState,
      );
    }
  };

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    const data = pageState?.rows;

    if (!value) {
      setPageState({
        ...pageState,
        updated: false,
        filteredData: [],
        total: pageState?.rows?.length,
      });
      return;
    }

    if (!data?.length) return;

    const keys = Object.keys(data[0]).filter(key =>
        typeof data[0][key] === 'string' ||
        typeof data[0][key] === 'number',
    );

    const updatedData = data.filter(item => {
      for (const key of keys) {
        const itemValue = item[key];
        if (!itemValue) continue;

        const stringValue = String(itemValue).toLowerCase();

        if (stringValue.startsWith(value)) return true;
        if (stringValue.includes(value)) return true;
      }
      return false;
    });

    setPageState({
      ...pageState,
      updated: true,
      filteredData: updatedData,
      total: updatedData.length,
    });
  };

  async function handleExport() {
    try {

      if (pageState?.rows?.length == 0) {
        return;
      }

      setPageState({...pageState, isLoading: true});
      const rows = pageState?.rows;

      const jsonData = rows.map((row) => {
        const parsedRow = {};
        columns.forEach((colDef) => {
          const label = colDef.headerName;
          let value;
          if (colDef.valueGetter) {
            value = colDef.valueGetter('', row);
            if (colDef?.type == 'date') {
              value = dayjs(value).format('DD/MM/YYYY');
            }
          } else {
            value = row[colDef.field];
          }
          parsedRow[label] = value || '';
        });
        return parsedRow;
      });

      const date = dayjs().format('lll').replace(':', '.');
      const fileName = `${exportFileName} [${date}]`;
      downloadCSV(jsonData, fileName);

      setPageState({...pageState, isLoading: false});

    } catch (e) {
      console.log('Export Failed', e);
      setPageState({...pageState, isLoading: false});
      enqueueSnackbar(t('global.alerts.exportTable.fail'), {variant: 'error'});
    }

  }

  // Modify the window size constant
  const WINDOW_SIZE = 20;
  const [visibleRange, setVisibleRange] = useState(
      {start: 0, end: WINDOW_SIZE});

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {

              const currentData = pageState?.updated ?
                  pageState?.filteredData :
                  pageState?.rows;
              const currentLength = currentData?.length || 0;

              // Only load more if we haven't reached the end
              if (visibleRange.end < currentLength) {
                setVisibleRange(prev => ({
                  start: 0, // Keep start at 0 to maintain all loaded items
                  end: Math.min(currentLength, prev.end + WINDOW_SIZE), // Add next batch
                }));
              }
            }
          });
        },
        {threshold: 0.1},
    );

    // Observe only the last item as sentinel
    const items = document.querySelectorAll('.mobile-list-card');
    if (items.length > 0) {
      const lastItem = items[items.length - 1];
      observer.observe(lastItem);
    }

    return () => observer.disconnect();
  }, [pageState?.rows, pageState?.filteredData, visibleRange]);

  return (
      <Box px={2}>
        <>
          <Backdrop
              sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
              open={pageState?.isLoading}
          >
            <CircularProgress color="inherit"/>
          </Backdrop>
          <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              py={2}
          >
            <Button
                variant="text"
                size="small"
                onClick={() => {
                  handleExport();
                }}
                startIcon={<SaveAlt sx={{fontSize: 18}}/>}
            >
              Export
            </Button>
          </Stack>
          <TextField
              fullWidth
              variant="standard"
              placeholder="Search.."
              onChange={(e) => {
                setPageState({...pageState, searchTerm: e.target.value});
                if (pagination !== 'server') {
                  handleFilter(e);
                }
              }
              }
              InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        sx={{cursor: 'pointer'}}
                        onClick={(e) =>
                            setPageState(
                                {...pageState, searchTerm: e.target.value})
                        }
                    >
                      <Search/>
                    </InputAdornment>
                ),
              }}
              sx={{mb:2}}
          />
          {pageState?.rows?.length > 0 ?
              (!!pageState?.updated
                      ? pageState?.filteredData
                      : pageState?.rows
              )?.slice(0, visibleRange.end).map((row, index) => {
                return (
                    <MobileListCard
                        className="mobile-list-card"
                        key={row.id || index}
                        row={row}
                        columns={columns}
                        defaultExpanded={defaultExpanded}
                        Actions={() => (Actions ? Actions({row}) : null)}
                    />
                );
              }) :
              !pageState?.isLoading && (
                  <Stack
                      justifyContent={'center'}
                      paddingY={'3rem'}
                      minHeight={'50vh'}
                  >
                    <Typography variant="body2" textAlign={'center'}>
                      {t('listing.noDataText')}
                    </Typography>
                  </Stack>
              )
          }
        </>
        <CustomTablePagination
            rowsPerPageOptions={rowsPerPageOption}
            count={pageState?.total}
            rowsPerPage={pageState?.pageSize}
            page={pageState?.page}
            labelRowsPerPage="Per Page"
            slotProps={{
              select: {
                'aria-label': 'Per page',
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
                slots: {
                  firstPageIcon: FirstPageRoundedIcon,
                  lastPageIcon: LastPageRoundedIcon,
                  nextPageIcon: ChevronRightRoundedIcon,
                  backPageIcon: ChevronLeftRoundedIcon,
                },
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
  );
};
export default MobileCardsView;


export default function ListOverride(theme) {
  return {
    MuiList: {
      styleOverrides: {
        root: {
          '.MuiMenuItem-root': {
            '.MuiTouchRipple-child': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          // color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        // primaryTypographyProps: {
        //   typography: 'subtitle2',
        //   fontWeight: 500,
        //
        // },
        // secondaryTypographyProps: {
        //   component: 'span',
        // },
      },

      styleOverrides: {
        // root: {
        //   margin: 0,
        // },
        // multiline: {
        //   margin: 0,
        // },
      },
    },
  };
}

import {useState} from 'react';
import {Avatar, Badge, Box, Skeleton, useTheme} from '@mui/material';
import {
  BusinessTwoTone,
  PersonTwoTone,
  ShieldTwoTone,
} from '@mui/icons-material';

function ProfileImageViewer({
                              imgPath,
                              size = 'lg' || 'md' || 'sm' || 'xs' || 'xxs',
                              placeholderStyle = 'user-icon' || 'shield-icon' ||
                              'company-icon' ||
                              'initials',
                              text,
                              stringAvatar,
                              showBadge,
                              badgeContent,
                              badgeStyles,
                            }) {

  const hasImage = !!imgPath;

  const theme = useTheme();
  const [isLoadingImg, setIsLoadingImg] = useState(hasImage);

  const isLg = size === 'lg';
  const isMd = size === 'md';
  const isSm = size === 'sm';
  const isXs = size === 'xs';
  const isXXs = size === 'xxs';

  let PlaceholderIcon;
  let placeholderIconColor = theme.palette.grey['600'];

  switch (placeholderStyle) {
    case 'user-icon':
      PlaceholderIcon = PersonTwoTone;
      break;
    case 'shield-icon':
      PlaceholderIcon = ShieldTwoTone;
      placeholderIconColor = theme.palette.grey['400'];
      break;
    case 'company-icon':
      PlaceholderIcon = BusinessTwoTone;
      break;
    case 'initials':
      PlaceholderIcon = null;
      break;
    default:
      PlaceholderIcon = PersonTwoTone;
  }

  const lgStyles = {
    width: '104px',
    height: '104px',
    svg: {
      width: '60px',
      height: '60px',
    },
  };

  const mdStyles = {
    width: '60px',
    height: '60px',
    svg: {
      width: '30px',
      height: '30px',
    },
  };

  const smStyles = {
    width: '40px',
    height: '40px',
    svg: {
      width: '30px',
      height: '30px',
    },
  };

  const xsStyles = {
    width: '32px',
    height: '32px',
    svg: {
      width: '24px',
      height: '24px',
    },
  };

  const xxsStyles = {
    width: '28px',
    height: '28px',
    svg: {
      width: '20px',
      height: '20px',
    },
  };

  return <Box
      sx={{
        ...(isLg && lgStyles),
        ...(isMd && mdStyles),
        ...(isSm && smStyles),
        ...(isXs && xsStyles),
        ...(isXXs && xxsStyles),
        borderRadius: '100%',
        overflow: showBadge ? 'visible' : 'hidden',
        backgroundColor: theme.palette.grey[100],
        border: !isLoadingImg && '1px solid',
        borderColor: theme.palette.grey[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        flexShrink: 0,
        'img': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      }}
  >
    {
        hasImage && isLoadingImg && <Skeleton
            animation={'wave'}
            variant={'circular'}
            sx={{
              ...(isLg && lgStyles),
              ...(isSm && smStyles),
              ...(isXs && xsStyles),
              ...(isXXs && xxsStyles),
            }}
        />
    }

    {
        hasImage && !showBadge && <img
            src={imgPath}
            alt="profile-img"
            style={isLoadingImg ? {display: 'none'} : {}}
            onLoad={() => {
              setIsLoadingImg(false);
            }}
        />
    }

    {
        hasImage && showBadge &&
        <Badge
            sx={{...badgeStyles}}
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            badgeContent={badgeContent}
        >
          <Avatar src={imgPath}/>
        </Badge>
    }

    {
        !hasImage && PlaceholderIcon &&
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          'svg': {
            fill: placeholderIconColor,
          },
        }}>
          <PlaceholderIcon/>
        </Box>
    }
    {
        !hasImage && placeholderStyle == 'initials' &&
        <Badge
            sx={{...badgeStyles}}
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            badgeContent={badgeContent}
        >
          <Avatar {...stringAvatar(text)}/>
        </Badge>
    }

  </Box>;
}

export default ProfileImageViewer;

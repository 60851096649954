import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_TRAINING} from '../../constants/modules';
import { lazy } from 'react';
const PageTrainingList = lazy(()=> import( '../../pages/Trainings/PageTrainingList'));
const  PageTrainingAddEdit = lazy(()=> import( '../../pages/Trainings/PageTrainingAddEdit'));
const  PageTrainingDetail = lazy(()=> import( '../../pages/Trainings/PageTrainingDetail'));


const RoutesTraining = (
    <>
      {/*EVENTS*/}
      <Route path={`/training`}>
        <Route element={<AccessProtected
                capabilities={CAPABILITY_READ_ALL}
                module={MODULE_TRAINING}
            />}>
        <Route index element={<PageTrainingList tab={0}/>}/>
          <Route path={'active'} element={<PageTrainingList tab={0}/>}/>
          <Route path={'past'} element={<PageTrainingList tab={1}/>}/>
        </Route>
        {/*CREATE EVENT*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_TRAINING}
        />}>
          <Route path={`add`} element={<PageTrainingAddEdit/>}/>
        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_TRAINING}
        />}>
          <Route path={`edit`} element={<PageTrainingAddEdit/>}/>
        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_TRAINING}
            checkForAccess={false}
        />}>
        <Route path={`*`} element={<PageTrainingDetail/>}/>
        </Route>
      </Route>
    </>
);

export default RoutesTraining;

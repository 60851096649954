import { styled, TablePagination,tablePaginationClasses as classes, Box } from "@mui/material";

export const CustomTablePagination = styled(TablePagination)`

    position:sticky;
    bottom:-10px;
    width:100vw;

  & .${classes.toolbar} {

    display: flex;
    justify-content:space-between;
    padding:1rem .5rem;
    background: #fff;


    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;

    }
  }

  & .MuiTablePagination-input{
    margin:0;
  }
  & .${classes.select} {
    margin: 0 !important;
  }
  & .${classes.selectLabel} {
    margin: 0 !important;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
    margin:0;
  }
`;

export const MobileCardsViewContainer = styled(Box)`

    position:relative;

`
function PlumpyFinances(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} {...props} >
          <path
              d="M20.5,18c-0.017,0-0.197,0-0.5,0V9h0.211C21.199,9,22,8.199,22,7.211c0-0.732-0.445-1.389-1.125-1.661l-7.55-3.02c-0.851-0.34-1.801-0.34-2.652,0l-7.55,3.02C2.445,5.822,2,6.48,2,7.211C2,8.199,2.801,9,3.789,9H4v9c-0.303,0-0.483,0-0.5,0C2.672,18,2,18.672,2,19.5S2.672,21,3.5,21c0.176,0,16.824,0,17,0c0.828,0,1.5-0.672,1.5-1.5S21.328,18,20.5,18z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M20.5,18c0.176,0,0.343,0.036,0.5,0.092V8.809C20.761,8.927,20.496,9,20.211,9H18v9C19.471,18,20.458,18,20.5,18z"
              fill="currentColor"/>
          <path
              d="M3.5,18c0.042,0,1.029,0,2.5,0V9H3.789C3.504,9,3.239,8.927,3,8.809v9.283C3.157,18.036,3.324,18,3.5,18z"
              fill="currentColor"/>
          <path
              d="M14.378,14.685c0-2.59-2.859-2.236-2.859-3.586c0-0.795,0.625-0.879,0.841-0.879c0.213,0,0.402,0.051,0.562,0.123c0.376,0.17,0.822,0.012,1.06-0.325c0.305-0.432,0.147-1.039-0.335-1.256c-0.264-0.119-0.594-0.215-0.994-0.253V8.133c0-0.346-0.281-0.627-0.627-0.627s-0.627,0.281-0.627,0.627v0.508c-1.049,0.35-1.731,1.31-1.731,2.539c0,2.718,2.824,2.227,2.824,3.64c0,0.272-0.129,0.865-0.829,0.865c-0.311,0-0.58-0.088-0.799-0.202c-0.371-0.194-0.832-0.051-1.069,0.295l-0.025,0.037c-0.274,0.4-0.163,0.96,0.258,1.199c0.354,0.201,0.787,0.357,1.31,0.412v0.44c0,0.346,0.281,0.627,0.627,0.627c0.346,0,0.627-0.281,0.627-0.627v-0.553C13.753,16.958,14.378,15.9,14.378,14.685z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyFinances;

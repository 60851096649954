import {Outlet} from 'react-router-dom';
import {Box, useTheme} from '@mui/material';
import LayoutMainPage from '../LayoutMain/Page/LayoutMainPage';
import LayoutMainVerticalLayoutHeader from '../LayoutMain/VerticalLayoutHeader/LayoutMainVerticalLayoutHeader';


function LayoutTemparoryMain({role}) {

  const theme = useTheme();

  return (
      <Box
          sx={{
            height: '100vh',
            overflow: 'hidden',
            backgroundColor:theme.palette.background.neutral,
      }}
      >
        <LayoutMainVerticalLayoutHeader role={role} showBottomLayout={false}/>
        <LayoutMainPage>
          <Outlet/>
        </LayoutMainPage>
      </Box>
  );
}

export default LayoutTemparoryMain;

import {Breadcrumbs, Link as LinkMUI, Skeleton, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function BreadcrumbsStatic({options,loading}) {

  const isMobile = useIsMobile();

  const mobileOptions = options?.length? [options?.at(options?.length-1)] : []
  const defaultStyles = {
    textDecoration: 'none',
    fontSize: '20px',
    color: 'text.secondary',
    fontWeight: 500,
    textTransform:'capitalize'
  };

  const lastStyles = {
    color: 'text.primary',
  };

  return <Breadcrumbs>
    {
      ((isMobile?mobileOptions:options) || [])?.map((option, index, array) => {

        const IS_LAST = index === (array.length - 1);
        const SLOT = option?.slot;

        return (
            <Stack
                key={index}
                direction={'row'}
                spacing={2}
                alignItems={'center'}
            >
              {
                loading?
                <Skeleton sx={{height:'30px',width:'100px'}} />
                :
                <LinkMUI

                    component={option?.route ? Link : Typography}
                    to={option?.route}
                    sx={{
                      ...defaultStyles,
                      ...(IS_LAST && lastStyles),
                    }}
                >
                  {option.label}
                </LinkMUI>
              }
              {
                  !!SLOT && SLOT
              }
            </Stack>
        );
      })
    }
  </Breadcrumbs>;
}

export default BreadcrumbsStatic;

import { useSelector } from "react-redux"

const useIsDemo=()=>{

    const policies = useSelector(state=>state.app.policies);
    const club = policies?.club;

    return club?.isDemoClub;
}
export default useIsDemo;

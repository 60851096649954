function PlumpyClosed(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M3.843,17C2.438,17,2,15.408,2,14.5C2,13.45,2.461,12,3.843,12c0.405,0,0.704,0.112,0.917,0.245c0.242,0.15,0.294,0.481,0.125,0.709h0c-0.146,0.197-0.409,0.246-0.632,0.141c-0.089-0.042-0.197-0.07-0.33-0.07c-0.278,0-0.858,0.221-0.858,1.475s0.565,1.475,0.865,1.475c0.122,0,0.229-0.034,0.319-0.081c0.216-0.114,0.479-0.071,0.632,0.12l0.001,0.001c0.178,0.222,0.137,0.552-0.097,0.713C4.573,16.874,4.268,17,3.843,17z"
              fill="currentColor"/>
          <path
              d="M21.84,14.514c0,1.797-0.962,2.359-1.971,2.359h-0.708c-0.346,0-0.626-0.28-0.626-0.626v-3.494c0-0.346,0.28-0.626,0.626-0.626h0.708C20.916,12.127,21.84,12.716,21.84,14.514z M20.761,14.514c0-1.255-0.619-1.448-0.878-1.448h-0.262v2.868h0.262C20.142,15.934,20.761,15.769,20.761,14.514z"
              fill="currentColor"/>
          <path
              d="M15.663,16.247v-3.494c0-0.346,0.28-0.626,0.626-0.626h1.194c0.261,0,0.473,0.212,0.473,0.473v0c0,0.261-0.212,0.473-0.473,0.473h-0.794v0.931h0.723c0.244,0,0.442,0.198,0.442,0.442v0c0,0.244-0.198,0.442-0.442,0.442h-0.723v1.018h0.874c0.261,0,0.473,0.212,0.473,0.473v0.02c0,0.261-0.212,0.473-0.473,0.473h-1.274C15.943,16.873,15.663,16.592,15.663,16.247z"
              fill="currentColor"/>
          <path
              d="M5.658,16.247V12.67c0-0.3,0.243-0.543,0.543-0.543h0c0.3,0,0.543,0.243,0.543,0.543v3.21h0.771c0.274,0,0.496,0.222,0.496,0.496l0,0c0,0.274-0.222,0.496-0.496,0.496H6.284C5.938,16.873,5.658,16.592,5.658,16.247z"
              fill="currentColor"/>
          <path
              d="M11.996,14.487c0,0.375-0.106,2.513-1.823,2.513S8.35,14.862,8.35,14.487C8.35,14.111,8.44,12,10.173,12S11.996,14.111,11.996,14.487z M10.897,14.487c0-0.206-0.023-1.448-0.724-1.448c-0.701,0-0.724,1.242-0.724,1.448s0.039,1.461,0.724,1.461C10.857,15.948,10.897,14.692,10.897,14.487z"
              fill="currentColor"/>
          <path
              d="M14.617,12.852c-0.133,0.188-0.382,0.276-0.591,0.181c-0.089-0.04-0.194-0.069-0.313-0.069c-0.12,0-0.469,0.047-0.469,0.49c0,0.753,1.595,0.556,1.595,2.001c0,0.833-0.519,1.544-1.481,1.544c-0.393,0-0.701-0.105-0.945-0.244c-0.235-0.133-0.297-0.446-0.144-0.669l0.014-0.021c0.132-0.193,0.389-0.273,0.596-0.165c0.122,0.064,0.272,0.113,0.446,0.113c0.391,0,0.463-0.331,0.463-0.482c0-0.788-1.575-0.514-1.575-2.031c0-0.865,0.6-1.501,1.462-1.501c0.318,0,0.568,0.066,0.758,0.152C14.699,12.273,14.787,12.611,14.617,12.852L14.617,12.852z"
              fill="currentColor"/>
          <path
              d="M4,9h16c1.657,0,3,1.343,3,3v5c0,1.657-1.343,3-3,3H4c-1.657,0-3-1.343-3-3v-5C1,10.343,2.343,9,4,9z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M6.805,9l4.655-3.8c0.315-0.254,0.764-0.254,1.079,0L17.2,9h3.162L13.8,3.648c-1.052-0.854-2.557-0.854-3.609,0L3.643,9 H6.805z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyClosed;

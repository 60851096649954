function PlumpyCourts(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M5,4h14c1.657,0,3,1.343,3,3v10c0,1.657-1.343,3-3,3H5c-1.657,0-3-1.343-3-3V7C2,5.343,3.343,4,5,4z"
              opacity=".35" fill="currentColor"/>
          <rect width="2" height="16" x="11" y="4" fill="currentColor"/>
          <path
              d="M22,8V7c0-0.35-0.06-0.68-0.18-0.99C18.59,6.1,16,8.75,16,12s2.59,5.9,5.82,5.99C21.94,17.68,22,17.35,22,17v-1c-2.21,0-4-1.79-4-4S19.79,8,22,8z"
              fill="currentColor"/>
          <path
              d="M2.18,6.01C2.06,6.32,2,6.65,2,7v1c2.21,0,4,1.79,4,4s-1.79,4-4,4v1c0,0.35,0.06,0.68,0.18,0.99C5.41,17.9,8,15.25,8,12S5.41,6.1,2.18,6.01z"
              fill="currentColor"/>
          <path d="M4,12c0,1.1-0.9,2-2,2v-4C3.1,10,4,10.9,4,12z"
                fill="currentColor"/>
          <path d="M20,12c0,1.1,0.9,2,2,2v-4C20.9,10,20,10.9,20,12z"
                fill="currentColor"/>
          <circle cx="12" cy="12" r="2" fill="currentColor"/>
      </svg>
  );
}

export default PlumpyCourts;

function PlumpyManageAdmins(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <circle cx="12" cy="8" r="5" opacity=".35" fill="currentColor"/>
          <path
              d="M23,19c0-0.38,0.171-0.719,0.44-0.946c0.239-0.203,0.344-0.525,0.234-0.819c-0.194-0.513-0.469-0.986-0.81-1.402c-0.192-0.234-0.514-0.323-0.796-0.215c-0.338,0.13-0.729,0.114-1.068-0.082c-0.34-0.196-0.55-0.528-0.605-0.887c-0.045-0.294-0.278-0.529-0.571-0.579C19.556,14.024,19.281,14,19,14s-0.556,0.024-0.823,0.07c-0.293,0.05-0.526,0.285-0.571,0.579C17.55,15.008,17.34,15.34,17,15.536c-0.339,0.196-0.729,0.212-1.068,0.082c-0.282-0.109-0.604-0.019-0.796,0.215c-0.342,0.417-0.617,0.889-0.81,1.402c-0.111,0.294-0.006,0.616,0.234,0.819C14.829,18.281,15,18.62,15,19c0,0.38-0.171,0.719-0.44,0.946c-0.239,0.203-0.344,0.525-0.234,0.819c0.194,0.513,0.469,0.986,0.81,1.402c0.192,0.234,0.514,0.323,0.796,0.215c0.338-0.13,0.729-0.114,1.068,0.082c0.34,0.196,0.55,0.528,0.605,0.887c0.045,0.294,0.278,0.529,0.571,0.579C18.444,23.976,18.719,24,19,24s0.556-0.024,0.823-0.07c0.293-0.05,0.526-0.285,0.571-0.579c0.055-0.36,0.265-0.691,0.605-0.887c0.339-0.196,0.729-0.212,1.068-0.082c0.282,0.109,0.604,0.019,0.796-0.215c0.342-0.417,0.617-0.889,0.81-1.402c0.111-0.293,0.006-0.616-0.234-0.819C23.171,19.719,23,19.38,23,19z M19,21c-1.105,0-2-0.896-2-2s0.895-2,2-2s2,0.896,2,2S20.105,21,19,21z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M14.56,19.946C14.829,19.719,15,19.38,15,19c0-0.38-0.171-0.719-0.44-0.946c-0.239-0.203-0.344-0.525-0.234-0.819 c0.169-0.448,0.411-0.859,0.694-1.235H6c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3h9.021c-0.284-0.376-0.525-0.786-0.694-1.235 C14.216,20.472,14.321,20.149,14.56,19.946z"
              fill="currentColor"/>
          <circle cx="19" cy="19" r="2" fill="currentColor"/>
          <circle cx="19" cy="19" r="2" fill="currentColor"/>
      </svg>
  );
}

export default PlumpyManageAdmins;

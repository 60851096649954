import {
  alpha,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Tooltip,
  useTheme,
} from '@mui/material';
import {NavLink} from 'react-router-dom';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {useState} from 'react';

function LayoutMainNavVertical({
                                 navItems,
                                 collapsed,
                                 spacing = 'default' || 'compact',
                               }) {

  return (
      <List disablePadding sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: .5,
      }}>
        {
          navItems.map((item, index) => {
            if (item.canAccess) {
              return (
                  <MenuItem key={index} item={item} collapsed={collapsed}
                            spacing={spacing}/>
              );
            }
          })
        }
      </List>
  );
}

function MenuItem({item, isChild, collapsed, spacing}) {

  const hasChildren = item?.subMenuItems;

  const Component = hasChildren ? MultiLevel : SingleLevel;
  return <Component
      item={item}
      isChild={isChild}
      collapsed={collapsed}
      spacing={spacing}
  />;
}

function SingleLevel({item, isChild, collapsed, spacing}) {

  const theme = useTheme();

  const isCompact = spacing === 'compact';

  return (
      <ListItem disablePadding>
        <Tooltip
            title={collapsed && item.name}
            placement={'right'}
            arrow
        >
          <ListItemButton
              component={NavLink}
              to={item.route}
              sx={{
                margin: 0,
                borderRadius: '8px',
                color: 'text.secondary',
                py: 1.25,
                '&.active': {
                  backgroundColor: alpha(theme.palette.primary.main, .2),
                  color: theme.palette.primary.main,
                  'svg': {
                    color: theme.palette.primary.main,
                  },
                },
              }}
          >
            <ListItemIcon>
              {item.icon && <item.icon/>}
            </ListItemIcon>
            {
                !collapsed &&
                <ListItemText
                    sx={{
                      ...(isCompact) && {
                        marginTop: "0 !important",
                        marginBottom: "0 !important",
                        '& .MuiTypography-root': {
                          fontWeight: 500,
                          fontSize: '14px',
                          margin: 0,
                        },
                      },
                    }}
                >
                  {item.name}
                </ListItemText>
            }
          </ListItemButton>
        </Tooltip>

      </ListItem>
  );
}

function MultiLevel({item, isChild, spacing}) {

  const theme = useTheme();

  const isCompact = spacing === 'compact';

  const {subMenuItems: children} = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
      <>
        <ListItem onClick={handleClick} disablePadding>
          <ListItemButton
              sx={{
                borderRadius: '8px',
                color: 'text.secondary',
              }}
          >
            <ListItemIcon>
              {item.icon && <item.icon/>}
            </ListItemIcon>
            <ListItemText
                sx={{
                  ...(isCompact) && {
                    marginTop: "0 !important",
                    marginBottom: "0 !important",
                    '& .MuiTypography-root': {
                      fontWeight: 500,
                      fontSize: '14px',
                      margin: 0,
                    },
                  },
                }}
            >
              {item.name}
            </ListItemText>
            {open ? <ExpandLess/> : <ExpandMore/>}
          </ListItemButton>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {
              children.map((child, key) => (
                  <MenuItem key={key} item={child} isChild={true} spacing={spacing}/>
              ))
            }
          </List>
        </Collapse>
      </>
  );
}

export default LayoutMainNavVertical;

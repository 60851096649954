import {Box} from '@mui/material';
import LayoutMainVerticalLayoutHeaderBottom from './LayoutMainVerticalLayoutHeaderBottom';
import LayoutMainVerticalLayoutHeaderTop from './LayoutMainVerticalLayoutHeaderTop';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import HeaderDemoBar from '../LayoutMainHeaderDemoBar';
import useIsDemo from '../../../hooks/access/useIsDemo';

function LayoutMainVerticalLayoutHeader({role, showBottomLayout = true}) {

  const isMobile = useIsMobile();
  const isDemo = useIsDemo();

  return (
      <Box
          component={'header'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position:'sticky',
            top:0,
            zIndex: 3,
          }}
      >
        {
            isDemo &&
            <HeaderDemoBar/>
        }
        <LayoutMainVerticalLayoutHeaderTop
            role={role}
            disableDropdown={!showBottomLayout}
        />
        {
            showBottomLayout && !isMobile &&
            <LayoutMainVerticalLayoutHeaderBottom role={role}/>
        }

      </Box>
  );
}

export default LayoutMainVerticalLayoutHeader;

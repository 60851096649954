function PlumpyWallets(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path d="M20,8H4c-1.105,0-2,0.895-2,2v6h20v-6C22,8.895,21.105,8,20,8z"
                opacity=".35" fill="currentColor"/>
          <path
              d="M20,6H4C2.895,6,2,6.895,2,8v2c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2V8C22,6.895,21.105,6,20,6z"
              fill="currentColor"/>
          <path
              d="M20,4H4C2.895,4,2,4.895,2,6v2c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2V6C22,4.895,21.105,4,20,4z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M15,12c0,1.657-1.343,3-3,3s-3-1.343-3-3H2v5c0,1.657,1.343,3,3,3h14c1.657,0,3-1.343,3-3v-5H15z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyWallets;

import {IconButton} from '@mui/material';
import useLogout from '../../../hooks/access/useLogout';
import PlumpyLogout from '../../../assets/icons/plumpy/PlumpyLogout';

function SiteLogoutBtn() {

  const {logout} = useLogout();

  return (
      <IconButton
          onClick={() => {
            logout();
          }}
      >
        <PlumpyLogout/>
      </IconButton>
  );
}

export default SiteLogoutBtn;

import {Box, useTheme} from '@mui/material';
import DemoTopBar from '../LayoutMainHeaderDemoBar';
import useIsDemo from '../../../hooks/access/useIsDemo';

function LayoutMainHorizontalLayoutHeader() {

  const theme = useTheme();
  const isDemo = useIsDemo();

  return (
      <Box
          sx={{
            top: 0,
            width: '100%',
            position: 'sticky',
            zIndex: 3,
          }}
      >
        {
            isDemo &&
            <DemoTopBar/>
        }
        {/*<Box*/}
        {/*    sx={{*/}
        {/*      height: LAYOUT_MAIN_HORIZONTAL_LAYOUT_HEADER_HEIGHT,*/}

        {/*      px: '40px',*/}
        {/*      display: 'flex',*/}
        {/*      justifyContent: 'space-between',*/}
        {/*      alignItems: 'center',*/}
        {/*      ...bgBlur({color: theme.palette.background.neutral}),*/}
        {/*    }}*/}
        {/*>*/}
        {/*  <LayoutMainHeaderClubSelect style={'expanded'}/>*/}
        {/*  <LayoutMainHeaderActions/>*/}
        {/*</Box>*/}
      </Box>

  );
}

export default LayoutMainHorizontalLayoutHeader;

function PlumpyBooking(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} {...props} >
        <path
            d="M18,20H6c-1.657,0-3-1.343-3-3V8.766c0-1.09,0.591-2.094,1.543-2.623l6-3.333c0.906-0.504,2.008-0.504,2.914,0l6,3.333C20.409,6.672,21,7.676,21,8.766V17C21,18.657,19.657,20,18,20"
            opacity=".35" fill="currentColor"/>
        <path
            d="M17,10.636H7v10.272c0,0.811,0.845,1.338,1.563,0.976L12,20.147l3.437,1.736C16.155,22.246,17,21.718,17,20.908V10.636z"
            fill="currentColor"/>
        <path
            d="M17,10c-0.257,0-9.743,0-10,0c-0.552,0-1,0.448-1,1s0.448,1,1,1c0.257,0,9.743,0,10,0c0.552,0,1-0.448,1-1S17.552,10,17,10z"
            fill="currentColor"/>
      </svg>
  );
}

export default PlumpyBooking;

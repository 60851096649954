import {Navigate, Outlet} from 'react-router-dom';
import useUser from '../hooks/access/useUser';
import useIsAuthenticated from '../hooks/access/useIsAuthenticated';
import {useEffect} from 'react';
import useIsDemo from '../hooks/access/useIsDemo';
import {useHotjar} from '../hooks/hotjar/useHotjar';

function AccessAuthenticated() {

  const user = useUser();
  const isAuthenticated = useIsAuthenticated();
  const isDemo= useIsDemo();

  useHotjar(isDemo);

  return (isAuthenticated && user) ?
      <Outlet/> :
      <Navigate to="/login"/>;
}

export default AccessAuthenticated;

import {
  Box,
  Breadcrumbs,
  Link as LinkMUI,
  Stack,
  Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import useIsMobile from '../../../hooks/ui/useIsMobile';

function BreadcrumbsStatic({options, loading}) {

  const isMobile = useIsMobile();

  const mobileOptions = options?.length ?
      [options?.at(options?.length - 1)] :
      [];

  const defaultStyles = {
    textDecoration: 'none',
    fontSize: '20px',
    color: 'text.secondary',
    fontWeight: 700,
    textTransform: 'capitalize',
  };

  const lastStyles = {
    color: 'text.primary',
  };

  if (loading) {
    return;
  }

  return <Breadcrumbs separator={<Separator/>}>
    {
      ((isMobile ? mobileOptions : options) || [])?.map(
          (option, index, array) => {

            const IS_LAST = index === (array.length - 1);
            const SLOT = option?.slot;

            return (
                <Stack
                    key={index}
                    direction={'row'}
                    spacing={2}
                    alignItems={'center'}
                >
                  <LinkMUI
                      component={option?.route ? Link : Typography}
                      to={option?.route}
                      sx={{
                        ...defaultStyles,
                        ...(IS_LAST && lastStyles),
                      }}
                  >
                    {option.label}
                  </LinkMUI>
                  {
                      !!SLOT && SLOT
                  }
                </Stack>
            );
          })
    }
  </Breadcrumbs>;
}

function Separator() {
  return (
      <Box
          component="span"
          sx={{
            width: 4,
            height: 4,
            borderRadius: '50%',
            bgcolor: 'text.disabled',
          }}
      />
  );
}

export default BreadcrumbsStatic;

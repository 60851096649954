function PlumpyTennisBall(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <circle cx="12" cy="12" r="10" opacity=".35" fill="currentColor"/>
          <path
              d="M2.05,11C2.02,11.33,2,11.66,2,12s0.02,0.67,0.05,1c4.92,0.03,8.92,4.03,8.95,8.95c0.33,0.03,0.66,0.05,1,0.05s0.67-0.02,1-0.05C12.97,15.92,8.08,11.03,2.05,11z"
              fill="currentColor"/>
          <path
              d="M21.95,11c-4.92-0.03-8.92-4.03-8.95-8.95C12.67,2.02,12.34,2,12,2s-0.67,0.02-1,0.05C11.03,8.08,15.92,12.97,21.95,13c0.03-0.33,0.05-0.66,0.05-1S21.98,11.33,21.95,11z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyTennisBall;

import {useTranslate} from '@tolgee/react';
import {useSelector} from 'react-redux';
import useHasCapability from '../../../hooks/access/useHasCapability';
import {
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
} from '../../../constants/capabilities';
import {
  MODULE_ADMINS,
  MODULE_BOOKINGS,
  MODULE_CLUBS,
  MODULE_COMPANY,
  MODULE_COURTS,
  MODULE_CUSTOMERS,
  MODULE_EVENTS,
  MODULE_EXTRAS,
  MODULE_FINANCIALS,
  MODULE_HOLIDAYS,
  MODULE_INTEGRATIONS,
  MODULE_OPEN_MATCHES,
  MODULE_PACKAGES,
  MODULE_POLICIES,
  MODULE_PRICING,
  MODULE_PROFILE,
  MODULE_REPORTS,
  MODULE_SCHEDULE,
  MODULE_TRAINING,
  MODULE_WALLET,
} from '../../../constants/modules';
import {callGTMEvent} from '../../../utils/gtmEvents';
import {
  CalendarTodayTwoTone,
  CreditCardTwoTone,
  DoNotDisturbTwoTone,
  GroupTwoTone,
  PeopleTwoTone,
  SettingsTwoTone,
  SpokeTwoTone,
  SportsBaseballTwoTone,
  SurroundSoundTwoTone,
  TuneTwoTone,
  VerifiedUserTwoTone,
  WorkTwoTone,
} from '@mui/icons-material';
import PlumpySchedule from '../../../assets/icons/plumpy/PlumpySchedule';
import PlumpyBooking from '../../../assets/icons/plumpy/PlumpyBooking';
import PlumpyOpen from '../../../assets/icons/plumpy/PlumpyOpen';
import PlumpyEvents from '../../../assets/icons/plumpy/PlumpyEvents';
import PlumpyTrainings from '../../../assets/icons/plumpy/PlumpyTrainings';
import PlumpyWallets from '../../../assets/icons/plumpy/PlumpyWallets';
import PlumpyFinances from '../../../assets/icons/plumpy/PlumpyFinances';
import PlumpyCustomers from '../../../assets/icons/plumpy/PlumpyCustomers';
import PlumpyReports from '../../../assets/icons/plumpy/PlumpyReports';
import PlumpyPackages from '../../../assets/icons/plumpy/PlumpyPackages';
import PlumpySettings from '../../../assets/icons/plumpy/PlumpySettings';
import PlumpyCourts from '../../../assets/icons/plumpy/PlumpyCourts';
import PlumpyTennisBall from '../../../assets/icons/plumpy/PlumpyTennisBall';
import PlumpyPricing from '../../../assets/icons/plumpy/PlumpyPricing';
import PlumpyClosed from '../../../assets/icons/plumpy/PlumpyClosed';
import PlumpyPolicies from '../../../assets/icons/plumpy/PlumpyPolicies';
import PlumpyCompany from '../../../assets/icons/plumpy/PlumpyCompany';
import PlumpyClubs from '../../../assets/icons/plumpy/PlumpyClubs';
import PlumpyManageAdmins
  from '../../../assets/icons/plumpy/PlumpyManageAdmins';
import PlumpyPlugin from '../../../assets/icons/plumpy/PlumpyPlugin';
import PlumpyAdmins from '../../../assets/icons/plumpy/PlumpyAdmins';
import PlumpyProfile from '../../../assets/icons/plumpy/PlumpyProfile';

function useNavItems(role) {

  const {t} = useTranslate();

  const selectedClubIntegrationList = useSelector(
      state => state.app.clubsIntegrations);

  const clubManagerSettingsNavItems = [
    {
      icon: PlumpyCourts,
      name: t('settings.clubModule.cardCourt.name'),
      route: `/settings/courts`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_COURTS),
      onClick: () => callGTMEvent('settings', 'clicksOnCourtsFromDropown'),
    },
    {
      icon: PlumpyTennisBall,
      name: t('settings.clubModule.cardExtra.name'),
      route: `/settings/extras`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_EXTRAS),
      onClick: () => callGTMEvent('settings', 'clicksOnExtrasFromDropdown'),
    },
    {
      icon: PlumpyPricing,
      name: t('settings.clubModule.cardPricing.name'),
      route: `/settings/pricing`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_PRICING),
      onClick: () => callGTMEvent('settings', 'clicksOnPricingFromDropdown'),
    },
    {
      icon: PlumpyClosed,
      name: t('settings.clubModule.cardHolidays.name'),
      route: `/settings/holidays`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_HOLIDAYS),
      onClick: () => callGTMEvent('settings', 'clicksOnHolidayFromDropdown'),
    },
    {
      icon: PlumpyPolicies,
      name: t('settings.clubModule.cardPolicies.name'),
      route: `/settings/policies`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_POLICIES), //need to change it letter
      onClick: () => callGTMEvent('settings',
          'clicksOnPoliciesFromDropdown'),
    },
    {
      icon: PlumpyCompany,
      name: t('settings.adminModule.cardCompany.name'),
      route: `/settings/company/detail`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ, MODULE_COMPANY),
      onClick: () => callGTMEvent('settings', 'clicksOnCompanyFromDropdown'),
    },

    {
      icon: PlumpyClubs,
      name: t('settings.adminModule.cardClub.name'),
      route: `/settings/clubs`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_CLUBS),
      onClick: () => callGTMEvent('settings', 'clicksOnClubsFromDropdown'),
    },
    {
      icon: PlumpyManageAdmins,
      name: t('settings.adminModule.cardAdmin.name'),
      route: `/settings/manage-admins`,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_ADMINS),
      onClick: () => callGTMEvent('settings', 'clicksOnAdminsFromDropdown'),
    },
    {
      icon: PlumpyPlugin,
      name: t('settings.adminModule.cardIntegeration.name'),
      route: '/settings/integration',
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL,
          MODULE_INTEGRATIONS) && selectedClubIntegrationList?.length > 0,
      onClick: () => callGTMEvent('settings',
          'clicksOnIntegrationsFromDropdown'),
    },
    {
      icon: PlumpyPlugin,
      name: t('customerProfile.tabs.profile'),
      route: '/profile',
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ,
          MODULE_PROFILE) ,
      // onClick: () => callGTMEvent('settings',
      //     'clicksOnIntegrationsFromDropdown'),
    },
  ];

  const clubMangerNavItems = [
    {
      name: t('sideBar.link.textSchedule'),
      route: `/schedule`,
      // icon: CalendarTodayTwoTone,
      icon: PlumpySchedule,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_SCHEDULE),
      onClick: () => callGTMEvent('headers', 'clickOnSchedule'),
    },
    {
      name: t('sideBar.link.textBooking'),
      route: `/bookings`,
      icon: PlumpyBooking,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_BOOKINGS),
      onClick: () => callGTMEvent('headers', 'clickOnBookings'),
    },
    {
      name: t('sideBar.link.textOpenMatches'),
      route: `/openMatches`,
      icon: PlumpyOpen,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_OPEN_MATCHES),
      onClick: () => callGTMEvent('headers', 'clickOnBookings'),
    },
    {
      name: t('navItems.link.textEvents'),
      route: `/events`,
      icon: PlumpyEvents,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_EVENTS),
      onClick: () => callGTMEvent('headers', 'clickOnEvents'),
    },
    {
      name: t('sideBar.link.textTraining'),
      route: `/training`,
      icon: PlumpyTrainings,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_TRAINING),
      onClick: () => callGTMEvent('headers', 'clickOnTraining'),
    },
    {
      name: t('sideBar.link.textWallet'),
      route: `/wallets`,
      icon: PlumpyWallets,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_WALLET),
      onClick: () => callGTMEvent('headers', 'clickOnWallets'),
    },
    {
      name: t('sideBar.link.textFinancials'),
      route: `/finances`,
      icon: PlumpyFinances,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_FINANCIALS),
      onClick: () => callGTMEvent('headers', 'clickOnFinances'),
    },
    {
      name: t('sideBar.link.textCustomer'),
      route: `/customers`,
      icon: PlumpyCustomers,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_CUSTOMERS),
      onClick: () => callGTMEvent('headers', 'clickOnCustomers'),
    },
    {
      name: t('sideBar.link.textReport'),
      route: `/reports`,
      icon: PlumpyReports,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_REPORTS),
      onClick: () => callGTMEvent('headers', 'clickOnReports'),
    },
    {
      name: t('sideBar.link.textPackage'),
      route: `/packages`,
      icon: PlumpyPackages,
      disabled: false,
      canAccess: useHasCapability(CAPABILITY_READ_ALL, MODULE_PACKAGES), //letter change with packages module
      onClick: () => callGTMEvent('headers', 'clickOnPackages'),
    },
    // {
    //   name: t('sideBar.link.textCommunication'),
    //   route: '/communications',
    //   icon: MailIcon,
    //   disabled: true,
    //   canAccess: true,
    // },
    {
      name: t('sideBar.link.textSetting'),
      route: `/settings`,
      icon: PlumpySettings,
      disabled: false,
      canAccess: clubManagerSettingsNavItems?.filter(
          x => !!x?.canAccess)?.length > 0,
      onClick: () => callGTMEvent('headers', 'clickOnSettings'),
      // subMenuItems: clubManagerSettingsNavItems,
    }];

  const superAdminNavItems = [
    {
      name: t('nav.companies'),
      route: `/companies`,
      icon: PlumpyCompany,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('nav.clubs'),
      route: `/clubs`,
      icon: PlumpyClubs,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textCustomer'),
      route: `/customers`,
      icon: GroupTwoTone,
      disabled: false,
      canAccess: false,
    },
    {
      icon: PlumpyAdmins,
      name: t('nav.link.companyAdmins'),
      route: `/company-admins`,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textSetting'),
      route: `/settings`,
      icon: PlumpySettings,
      disabled: false,
      canAccess: true,
      subMenuItems: [
        {
          icon: PlumpyProfile,
          name: t('nav.profile'),
          route: `/settings/profile`,
          disabled: false,
          canAccess: true,
        },
        {
          icon: PlumpyManageAdmins,
          name: t('nav.manageSuperAdmins'),
          route: `/settings/super-admins`,
          disabled: false,
          canAccess: true,
        },
      ],
    },
  ];

  if (role === 'club_manager') {
    return clubMangerNavItems;
  }

  if (role === 'super_admin') {
    return superAdminNavItems;
  }

}

export default useNavItems;

import { Avatar, Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useTranslate } from "@tolgee/react";
import IconInfo from "../../assets/icons/IconInfo";
import { useSelectedClub } from "../../hooks/clubs/useSelectedClub";
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import useIsMobile from "../../hooks/ui/useIsMobile";
import { Call } from "@mui/icons-material";

const DemoTopBar=()=>{

    const {t} = useTranslate();
    const selectedClub = useSelectedClub();
    const playerSideUrl = `${process.env.REACT_APP_PLAYER_APP_URL}/club/${selectedClub?.id}?demo=${true}`;
    const isMobile = useIsMobile();

    const DemoViewText=()=>{
        return(
            <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            columnGap={1.5}
            >
                <IconInfo/>
                <Typography color={'#fff'}>
                    {t('demo.topBar.text')}
                </Typography>
            </Stack>
        )
    }


    return(
        <Grid
        container
        rowGap={1}
        alignItems={'center'}
        bgcolor={'#14213D'}
        minHeight={'50px'}
        padding={isMobile?'6px 12px':'0 24px'}
        >

            {
                isMobile &&
                <Grid
                item
                xs={12}
                >
                    <DemoViewText/>
                </Grid>

            }
            <Grid
            item
            xs={6}
            >
                <Stack
                direction={'row'}
                alignItems={'center'}
                columnGap={1.5}
                >
                    {
                    !isMobile &&
                    <DemoViewText/>
                    }
                    <Button
                    component={'a'}
                    href={playerSideUrl}
                    target="_blank"
                    size="medium"
                    variant="contained"
                    sx={{
                        height:'25px',
                        borderRadius:'8px',
                        bgcolor:'#F6A934A6',
                        fontSize:'13px',
                        '&:hover': {
                            bgcolor: '#F6A934',
                        }
                    }}
                    >
                        {t('demo.topBar.playerSide.text')}
                    </Button>
                </Stack>
            </Grid>
            <Grid
            item
            xs={6}
            >
                <Stack
                direction={'row'}
                gap={1}
                alignItems={'center'}
                justifyContent={'flex-end'}
                >
                    <Button
                    className={'chat-Btn'}
                    size="small"
                    sx={{color:'#fff',textDecoration:'underline',minWidth:'auto',padding:0}}
                    >
                        <MarkChatUnreadIcon sx={{height:'20px',width:'20px',margin:'0 .35rem'}}/>
                        {!isMobile && t('demo.topBar.intercom.text')}
                    </Button>
                    <Box
                    sx={{
                        height:'20px',width:'1px',
                        background:'#fff',
                        margin:'0 2px 0px 10px'
                    }}
                    />

                    <Button
                    size="small"
                    component={'a'}
                    href={'https://padelos.co/contact-us/'}
                    target="_blank"
                    sx={{color:'#fff',textDecoration:'underline',minWidth:'auto',padding:0}}
                    >
                        <Call sx={{height:'20px',width:'20px',margin:'0 .35rem'}}/>
                        {!isMobile && t('demo.topBar.scheduleCall.text')}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    )

}
export default DemoTopBar;

import {RESET_REDUX_STATE, USER_LOGOUT} from '../../vendor/redux/constants';
import {persistor} from '../../vendor/redux/configure-store';
import {useDispatch} from 'react-redux';
import { shutdown } from '@intercom/messenger-js-sdk';

function useLogout(){

  const dispatch = useDispatch();

  function logout(){
    localStorage.removeItem('user');
    localStorage.removeItem('selectedClub');
    localStorage.removeItem('persist:app');
    dispatch({type: RESET_REDUX_STATE});
    dispatch({type: USER_LOGOUT});
    persistor.purge();
    shutdown();
  }

  return {logout}

}

export default useLogout;

import {Box} from '@mui/material';
import {LAYOUT_MAIN__PAGE_PADDING_BOTTOM} from '../../../constants/ui';
import ContainerFullWidth
  from '../../../components/_Global/Containers/ContainerFullWidth';

function LayoutMainPage({children}) {

  return (
      <Box
          component={'main'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '100%',
            position: 'relative',
            zIndex: 0,
          }}
      >
        <ContainerFullWidth>
          {children}
        </ContainerFullWidth>
        <Box sx={{minHeight: LAYOUT_MAIN__PAGE_PADDING_BOTTOM}}></Box>
        {/*Do not remove, here for future use*/}
        {/*<SiteFooter/>*/}
      </Box>
  );
}

export default LayoutMainPage;

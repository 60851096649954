
export function typography(theme) {
  return {
    'body2': {color: theme.palette.text.secondary},
    'h7': {
      fontSize: '18px',
      fontWeight: '500',
    },
    h5: {
      fontSize: '24px',
      fontWeight: '400',
    },
    'h5-bold': {
      fontSize: '24px',
      fontWeight: '700',
    },
    h6: {
      fontSize: '20px',
      fontWeight: '500',
    },
    'h6-bold': {
      fontSize: '20px',
      fontWeight: '700',
    },
  };
}

function PlumpyTrainings(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M19,1c-0.552,0-1,0.447-1,1s0.448,1,1,1s1-0.448,1-1S19.552,1,19,1z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M9,5.499C9,6.88,10.119,8,11.499,8C12.882,8,14,6.88,14,5.499C14,4.12,12.882,3,11.499,3C10.119,3,9,4.12,9,5.499z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M10,11.499C10,12.88,11.119,14,12.499,14C13.882,14,15,12.88,15,11.499C15,10.12,13.882,9,12.499,9C11.119,9,10,10.12,10,11.499z"
              fill="currentColor"/>
          <polygon
              points="14.946,10.983 10.07,12.067 11.106,15.131 16.302,15.417"
              fill="currentColor"/>
          <path
              d="M17.832,17.445l-1.846-2.769c0,0-0.701-1.201-1.69-1.535l-0.464-0.696c-0.307-0.46-0.927-0.584-1.387-0.277c-0.418,0.279-0.54,0.812-0.332,1.253c-0.671,0.448-1.03,1.617-1.03,1.617l-1.035,6.652l-0.01,0.035c-0.006,0.02,0,0.04-0.005,0.06L10,22l0.019-0.028c-0.012,0.445,0.258,0.861,0.707,0.99C10.817,22.987,10.909,23,11.001,23c0.435,0,0.835-0.286,0.961-0.726l1.508-5.278c0.091-0.4,0.656-0.49,0.928-0.163l1.252,1.401l-0.62,3.523c-0.135,0.535,0.191,1.078,0.728,1.212C15.839,22.99,15.921,23,16.001,23c0.448,0,0.855-0.303,0.969-0.758l1-4C18.038,17.969,17.988,17.68,17.832,17.445z"
              fill="currentColor"/>
          <path
              d="M7,12.999c-0.196,0-0.389-0.058-0.555-0.168l-3-1.999c-0.459-0.306-0.584-0.927-0.277-1.387c0.305-0.46,0.925-0.584,1.387-0.277l2.655,1.77l4.594-0.918c0.546-0.111,1.069,0.242,1.177,0.784c0.108,0.542-0.243,1.068-0.784,1.177l-5,0.999C7.131,12.992,7.065,12.999,7,12.999z"
              fill="currentColor"/>
          <path
              d="M14.001,12c-0.367,0-0.72-0.202-0.896-0.553c-0.247-0.494-0.047-1.095,0.447-1.342l3.63-1.815l0.869-2.606c0.175-0.523,0.736-0.807,1.265-0.632c0.523,0.174,0.807,0.74,0.632,1.265l-1,2.999c-0.083,0.251-0.264,0.459-0.501,0.578l-4,2.001C14.304,11.966,14.151,12,14.001,12z"
              fill="currentColor"/>
          <polygon
              points="12.209,9.022 17.108,8.212 18.009,5.814 17.458,9.053 12.811,10.929"
              fill="currentColor"/>
          <polygon points="11.569,9.183 6.531,11.183 12.075,11" fill="currentColor"/>
          <path
              d="M5.971,3.966C5.636,2.062,4.041,0.752,2.409,1.04s-2.682,2.064-2.347,3.968c0.196,1.109,0.82,2.009,1.63,2.513C2.286,7.89,2.706,8.48,2.827,9.168l0.16,0.908l1.97-0.347l-0.16-0.908C4.675,8.132,4.868,7.434,5.299,6.884C5.888,6.134,6.167,5.075,5.971,3.966z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M4.524,12.985L4.524,12.985c-0.544,0.096-1.063-0.267-1.158-0.811l-0.538-3l1.97-0.347l0.538,3C5.431,12.37,5.068,12.889,4.524,12.985z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyTrainings;

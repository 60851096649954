import {
  alpha,
  ButtonBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  popoverClasses,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {NavLink, useLocation} from 'react-router-dom';
import {useMemo, useRef, useState} from 'react';
import {ChevronRight, MoreVertTwoTone} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';

export function LayoutMainNavHorizontal({navItems}) {

  const location = useLocation();
  const {t} = useTranslate();
  const isHome = location.pathname === '/';

  const collapseXs = useMediaQuery('(max-width: 914px)');
  const collapseSm = useMediaQuery('(max-width: 1146px)');
  const collapseMd = useMediaQuery('(max-width: 1428px)');
  const collapseLg = useMediaQuery('(max-width: 1554px)');

  let [items, moreItems] = useMemo(() => {

    navItems = navItems?.filter(x=>!!x?.canAccess);
    if (collapseXs) {
      return [
        navItems.slice(0, 4),
        navItems.slice(4),
      ];
    }

    if (collapseSm) {
      return [
        navItems.slice(0, 5),
        navItems.slice(5),
      ];
    }

    if (collapseMd) {
      return [
        navItems.slice(0, 7),
        navItems.slice(7),
      ];
    }

    if (collapseLg) {
      return [
        navItems.slice(0, 9),
        navItems.slice(9),
      ];
    }

    return [navItems, null];

  }, [navItems, collapseXs, collapseSm, collapseMd, collapseLg]);

  return (
      <Stack
          direction={'row'}
          spacing={1}
          alignItems={'center'}
      >
        {
          items?.map((navItem, i) => {
            if (!navItem.canAccess) return;
            return <NavItem item={navItem} isHome={isHome}/>;
          })
        }
        {
            moreItems?.length > 0 &&
            <NavItem
                isHome={isHome}
                item={{
                  name: t('nav.more'),
                  route: null,
                  activeOnRoot: false,
                  // icon: MoreVertTwoTone,
                  disabled: false,
                  canAccess: true,
                  onClick: () => {
                  },
                  subMenuItems: moreItems,
                }}
            />
        }
      </Stack>
  );

}

function NavItem({item, isHome}) {

  const theme = useTheme();
  const itemRef = useRef(null);

  const [open, setOpen] = useState(false);

  const children = item?.subMenuItems;
  const hasChildren = children?.length > 0;
  const hasRoute = item?.route;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <>
        <ButtonBase
            ref={itemRef}
            component={hasRoute && NavLink}
            to={item?.route}
            disabled={item.disabled}
            onClick={() => {
              if (item?.onClick) {
                item?.onClick();
              }
              handleClose();
              if (!hasRoute) {
                setOpen((prevState) => !prevState);
              }
            }}
            className={(item?.activeOnRoot && isHome) && 'active'}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '6px 12px',
              borderRadius: '24px',
              columnGap: '6px',
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.primary,
              },
              '&.active': {
                backgroundColor: alpha(theme.palette.primary.main, 1),
                color: '#fff',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.dark,
                      1),
                },
              },
              '&.Mui-disabled': {
                color: theme.palette.text.disabled,
              },
            }}
        >
          {
              item.icon && <item.icon color={'inherit'} width={'20px'} fontSize={'small'} />
          }
          <Typography variant={'body2'} color={'inherit'}
                      fontWeight={500} lineHeight={.1}>
            {item.name}
          </Typography>
          {
              hasChildren &&
              <ChevronRight fontSize="small"/>
          }
        </ButtonBase>
        {
            hasChildren &&
            <SubMenu
                items={children}
                itemRef={itemRef}
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
            />
        }
      </>
  );
}

function SubMenu({items, itemRef, open, handleOpen, handleClose}) {

  const theme = useTheme();

  return (
      <Popover
          open={open}
          anchorEl={itemRef.current}
          anchorOrigin={
            {vertical: 'bottom', horizontal: 'left'}
          }
          transformOrigin={
            {vertical: 'top', horizontal: 'left'}
          }
          slotProps={{
            paper: {
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose,
            },
          }}
          sx={{
            pointerEvents: 'none',
            [`& .${popoverClasses.paper}`]: {
              minWidth: 160,
              ...(open && {
                pointerEvents: 'auto',
              }),
            },
          }}
      >
        <List>
          {
            items?.map((item) => {
              if (!item.canAccess) return;
              return (
                  <ListItem
                      sx={{p: 0}}

                  >
                    <ListItemButton
                        component={NavLink}
                        to={item.route}
                        disabled={item.disabled}
                        onClick={() => {
                          if (item?.onClick) {
                            item?.onClick();
                          }
                          handleClose();
                        }}
                        sx={{
                          px: 1,
                          py: .25,
                          borderRadius: '8px',
                          '&.active': {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                    >
                      <ListItemText
                          sx={{
                            fontSize: '14px',
                            fontWeight: '600 !important',
                          }}
                      >
                        {item.name}
                      </ListItemText>
                    </ListItemButton>

                  </ListItem>
              );
            })
          }

        </List>
      </Popover>
  );
}



function PlumpyProfile(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M20,8l-6-6H7C5.343,2,4,3.343,4,5v14c0,1.657,1.343,3,3,3h10c1.657,0,3-1.343,3-3V8z"
              opacity=".35" fill="currentColor"/>
          <path d="M14,6V2l6,6h-4C14.895,8,14,7.105,14,6z" fill="currentColor"/>
          <circle cx="4.5" cy="16.5" r="2.5" fill="currentColor"/>
          <path
              d="M7,20H2c-1.105,0-2,0.895-2,2c0,1.105,0.895,2,2,2h5c1.105,0,2-0.895,2-2C9,20.895,8.105,20,7,20z"
              fill="currentColor"/>
        </svg>
  );
}

export default PlumpyProfile;

function PlumpyPlugin(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
           width={24} {...props} >
          <path
              d="M20,20H4c-1.105,0-2-0.895-2-2V8c0-1.657,1.343-3,3-3h14c1.657,0,3,1.343,3,3v10C22,19.105,21.105,20,20,20z"
              opacity=".35" fill="currentColor"/>
          <path
              d="M15.5,3h1C17.328,3,18,3.672,18,4.5V5h-4V4.5C14,3.672,14.672,3,15.5,3z"
              fill="currentColor"/>
          <path
              d="M7.5,3h1C9.328,3,10,3.672,10,4.5V5H6V4.5C6,3.672,6.672,3,7.5,3z"
              fill="currentColor"/>
      </svg>
  );
}

export default PlumpyPlugin;

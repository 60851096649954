import {
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import {
  DynamicCardAccordion,
  DynamicCardContainer,
} from "../../../vendor/mui/styled-components/DynamicCardContainer";
import { sortArrayByKey } from "../../../utils/validation";
import { useState } from "react";
import { getTableValueInMobileView } from "../../../utils/ui";
import { ExpandLess, ExpandMore } from "@mui/icons-material";



const MobileListCard = (props) => {
  const { className,row, columns, defaultExpanded, Actions, showHeaderIcon=false} = props;

  const Header = AccordionSummary;
  const Body = AccordionDetails;
  const Footer = AccordionActions;

  const colDef = sortArrayByKey(columns, "order");

  const contentColumns = colDef?.filter(
    (x) => !x?.isPrimary && !x?.isSubPrimary
  );
  const childColumns = colDef?.filter((x) => !!x?.isChild);
  const headerFields = colDef?.filter((x) => x.isHeaderField) ?? undefined;
  const subHeaderFields = colDef?.filter((x) => x.isSubHeaderField) ?? undefined;

  const [expanded, setExpanded] = useState(defaultExpanded? 'panel1' : false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderHeaderFields=(type)=>{

    const fields = type=='header'? headerFields : subHeaderFields;

    const primary = fields?.find((x) => x.isPrimary) ?? undefined;
    const subPrimary = fields?.find((x) => x.isSubPrimary) ?? undefined;

    return (
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            flexGrow: 1,
            flexBasis: type == "header" ? "90%" : "100%",
          }}
        >
          {primary && primary?.render
            ? primary?.render({ row })
            : getTableValueInMobileView(
                primary?.headerName ?? primary?.label,
                row[primary?.field] ?? "",
                "flex-start"
              )}
          <>
            {subPrimary &&
              (subPrimary?.render
                ? subPrimary?.render({ row })
                : getTableValueInMobileView(
                    subPrimary?.headerName ?? subPrimary?.label,
                    row[subPrimary?.field] ?? ""
                  ))}
          </>
        </Stack>
        {type == "header" &&
          showHeaderIcon &&
          (expanded == "panel1" ? <ExpandLess /> : <ExpandMore />)}
      </Stack>
    );

  }

  return (
    <DynamicCardContainer
      className={className}
      slotProps={{ transition: { unmountOnExit: true } }}
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <Header
        id={`panel-header`}
        aria-controls={`panel-content`}
        className="header"
      >
        <Stack
        width={'100%'}
        >
          {renderHeaderFields('header')}
          {renderHeaderFields('subHeader')}
        </Stack>
      </Header>
      <Body className="body">

        <Divider/>
        <Box
        sx={{paddingTop:'.5rem'}}
        >
          {contentColumns
          ?.filter((x) => !x?.isChild && !x?.hide)
          .map((current, index) => {
            const children = childColumns?.filter(
              (x) => x?.parentId == current?.id
            );

            return current?.isExpandable ? (
              <DynamicCardAccordion key={index - 3}>
                <AccordionSummary
                  id={`panel-header`}
                  aria-controls={`panel-content`}
                >
                  {current?.render
                    ? current?.render({ row })
                    : getTableValueInMobileView(
                        current?.headerName ?? current?.label,
                        row[current?.field],
                        undefined,
                        {labelWeight:current?.labelWeight}
                      )}
                </AccordionSummary>
                <AccordionDetails>
                  {children?.map((child, childIndex) => {
                    return (
                      <Typography key={childIndex - 24}>
                        {child?.render
                          ? child?.render({ row })
                          : getTableValueInMobileView(
                              child?.headerName ?? child?.label,
                              row[child?.field],
                              undefined,
                              {labelWeight:child?.labelWeight}
                            )}
                      </Typography>
                    );
                  })}
                </AccordionDetails>
              </DynamicCardAccordion>
            ) : (
              <Typography>
                {current?.render
                  ? current?.render({ row })
                  : getTableValueInMobileView(current?.headerName ?? current?.label, row[current?.field],undefined,{labelWeight:current?.labelWeight})}
              </Typography>
            );
          })}
        </Box>
      </Body>
      <Footer>{Actions && <Actions />}</Footer>
    </DynamicCardContainer>
  );
};
export default MobileListCard;



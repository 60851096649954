import {IconButton, Stack} from '@mui/material';
import SiteLogoutBtn from '../../components/_Global/Site/SiteLogoutBtn';
import useIsDemo from '../../hooks/access/useIsDemo';
import PlumpyHelp from '../../assets/icons/plumpy/PlumpyHelp';
import SiteLayoutSwitcher
  from '../../components/_Global/Site/SiteLayoutSwitcher';

function LayoutMainHeaderActions({role}) {

  const isDemo = useIsDemo();

  return (
      <Stack
          direction={'row'}
          alignItems={'center'}
          columnGap={.5}
      >
        {/*<SiteLayoutSwitcher/>*/}
        {
            !isDemo &&
            <IconButton
                color={'action.active'}
                id="intercomBtn"
            >
              <PlumpyHelp/>
            </IconButton>
        }
        {/*<IconButton*/}
        {/*    color={'action.active'}*/}
        {/*    className="beamerTrigger"*/}
        {/*>*/}
        {/*  <Update/>*/}
        {/*</IconButton>*/}

        <SiteLogoutBtn/>

      </Stack>
  );
}

export default LayoutMainHeaderActions;

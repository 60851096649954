import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {LockOutlined, Visibility, VisibilityOff} from '@mui/icons-material';
import {Controller, useForm} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {getIsProduction} from '../utils/environments';
import IntercomComponent from '../init/IntercomComponent';

function AccessMaster() {

  const isProduction = getIsProduction();
  const {pathname} = useLocation();
  const isDemoPage = pathname=='/demo';

  const [hasAccess, setHasAccess] = useState(
      !!localStorage.getItem('masterAccess'));

  useEffect(()=>{

    if(isDemoPage){
      setHasAccess(true);
    }
  },[pathname])

  // directly returns component temporarily to avoid Protected page
    return <>
          <IntercomComponent/>
          <Outlet/>
    </>;

  if (!isProduction){
    if (hasAccess || isDemoPage) {
      return <>
        <IntercomComponent/>
        <Outlet/>
      </>;
    } else {
      return <MasterProtectionPage setHasAccess={setHasAccess}/>;
    }
  }

}

function MasterProtectionPage({setHasAccess}) {

  const {enqueueSnackbar} = useSnackbar();
  const {
    handleSubmit,
    control,
    setError,
    watch,
    formState: {errors},
  } = useForm();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const watched = watch();
  const handleLoginSubmit = () => {
    handleSubmit(async ({password}) => {
          if (watched.password !== 'Springstreet$$') {
            enqueueSnackbar('incorrect password', {variant: 'error'});
          } else {
            localStorage.setItem('masterAccess', true);
            setHasAccess(true);
          }
        },
        (errors) => {
          enqueueSnackbar('please enter password', {variant: 'error'});
        },
    )();
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((cur) => !cur);
  };

  const handleEnterKeySubmit = (e) => {
    e.key === 'Enter' && handleLoginSubmit();
  };

  return (
      <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
      >
        <Box
            sx={{
              minWidth: '414px',
            }}
        >
          <Stack alignItems={'center'}>

            <LockOutlined fontSize={'large'}
                          sx={{
                            fill: '#8c8c8c',
                            width: '40px',
                            height: '40px',
                            mb: 4,
                          }}/>

            <Typography variant={'h3'}>
              Protected Content
            </Typography>
            <Typography variant={'body1'}
                        color={'text.secondary'}
                        mb={4}>
              To view please enter password
            </Typography>
            <Controller
                name={'password'}
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter password',
                  },
                }}
                control={control}
                render={({field}) => {
                  return <TextField
                      required
                      fullWidth
                      label={'Password'}
                      size="medium"
                      placeholder={'Enter Password'}
                      type={isPasswordVisible ? 'text' : 'password'}
                      error={!!errors['password']}
                      helperText={errors['password'] &&
                          errors['password'].message}
                      onKeyDown={handleEnterKeySubmit}
                      InputProps={{
                        endAdornment: <InputAdornment
                            position="end"
                            sx={{cursor: 'pointer'}}
                            onClick={handleTogglePasswordVisibility}
                        >
                          {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                        </InputAdornment>,
                      }}
                      {...field}
                  />;
                }}
            />
            <Button
                fullWidth
                variant={'contained'}
                onClick={handleLoginSubmit}
                sx={{mt: 2}}
            >
              Login
            </Button>


          </Stack>

        </Box>

      </Box>
  );
}

export default AccessMaster;
